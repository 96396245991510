import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { newFontSize, weight } from '../../../../theme/fonts';

export const EditPermissionsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 80%;
  margin: auto;
  ${'' /* margin-top: 60px; */}

  .edit-permissions {
    margin-left: 50px;
    height: 100%;

    .contain__button-save {
      box-sizing: border-box;
      width: 100%;
      top: 70px;
      width: auto;
      position: fixed;
      display: flex;
      justify-content: flex-end;
      right: 20px;

      button {
        height: 30px;
        width: 100px;
        ${'' /* font-weight: bold; */}
        ${'' /* color: white; */}
          border-radius: 10px;
      }

      .cancel_button {
        color: lightgrey;
        font-size: 14px;
        ${'' /* font-weight: bold; */}
        margin-right: 20px;
        background-color: ${colors.primaryColorLight};

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .permission {
    box-sizing: border-box;
    // height: calc(100% - 55px);
    height: 100%;
    width: 100%;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;

      // border-bottom: 1px solid ${colors.greyLine};

      h3 {
        font-weight: ${weight.bold};
        color: ${colors.blue};
        font-size: ${newFontSize.h3};
      }

      img {
        margin-right: 2px;
      }
    }

    &__contain-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      width: 100%;
      max-width: 90%;
      height: 50%;
      column-gap: 10px;
      row-gap: 20px;
      justify-content: flex-start;
      ${'' /* overflow-y: auto; */}
      margin: auto;
      margin-top: 40px;
    }

    &__card {
      ${'' /* height:100px; */}
    }
  }
`;
