import styled from 'styled-components';

import { colors } from '../../theme/colors.js';

export const NumberingsNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 100%;
  margin: auto;
  position: fixed;
  background-color: ${colors.backgroundColor};
  border-radius: 15px;
  transition: 1.5s linear;
  left: ${(p) => (p.isMenuOpen ? '229px' : '70px')};

  ${
    '' /* .container__cards {
    width: 80%;
    margin: auto;
  } */
  }

  ${
    '' /* .contain__inputCard-component {
    padding-top: 100px;
  } */
  }

 #observaciones__3 {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;

    input {
      height: 100%;
      width: 700px;
      min-height: 230px;
      border-radius: 10px;
      padding: 20px;
      padding-top: 60px;
    }
  }

  #identification__2 {
    position: absolute;
    top: 10px;
    padding-left: 105px;
    width: 1000px;

    .input-disabled {
      background: none;
    }
  }

  .listnumber__container {
    transition: 1.5s linear;

    .form__container {
      margin: auto;
      border-radius: 10px;
      background-color: ${colors.backgroundColor};

      .contain__button-save {
        position: absolute;
        top: 70px;
        right: 20px;
        height: 35px;
        width: auto;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 50px;
        position: relative;
        .cancel_button {
          background-color: ${colors.primaryColor};
          color: white;
          height: 35px;
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          margin-right: 20px;
          ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}
          -webkit-border-radius: 15px 15px 15px 15px;
          -webkit-transition: all 0.5s linear;

          &:hover {
            color: white;
            background-color: ${colors.primaryColor};
            ${'' /* box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4); */}
            ${'' /* transform: scale(1.05); */}
          }
        }

        .save_edit_button {
          color: black;
          width: 100px;
          height: 35px;
          background-color: ${colors.greenGYGAS};
          font-size: 14px;
          border-radius: 10px;
          ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}
          -webkit-border-radius: 15px 15px 15px 15px;
          -webkit-transition: all 0.5s linear;
          &:hover {
            background-color: ${colors.greenGYGASLight};
            ${
              '' /* box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
            transform: scale(1.05); */
            }
          }
        }

        .save_button {
          height: 35px;
          color: black;
          border-radius: 10px;
          width: 100px;
          background-color: ${colors.greenGYGAS};
          font-size: 14px;
          margin-right: 20px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }

        .save_button,
        .next_step {
          color: black;
          background-color: ${colors.greenGYGAS};
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          margin-right: 20px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }
      }

      .contain__input {
        input {
          background-color: white;
          border: none;
          font-weight: bold;
          font-size: 20px;
          padding: 10px;
          width: 500px;
          max-width: unset;
        }
      }
      .contain__select {
        width: 100%;
      }
    }
  }
`;
