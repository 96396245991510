import styled from 'styled-components';
import { colors } from '../../../theme/colors.js';

export const ConfigurationAsteriskScreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .pageScreen__tabs {
    position: relative;
    top: 115px;
  }
  .form__body__prefs {
    .contain__inputCard-component {
      padding-top: unset;
      background-color: ${colors.primaryColorLight};
      .stepForm__contain-typeInput {
        border-radius: 0 15px 15px 15px;
      }
    }
  }
  .pageScreen__tabs {
    width: 60%;
    ${'' /* padding-top: 90px; */}
  }
  .pageScreen__form {
    height: auto;
    width: 100%;
    ${'' /* margin-top: 90px; */}
  }
  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;

    &__drop {
      display: flex;
    }
  }
`;
