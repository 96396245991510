import styled from 'styled-components';
import { colors } from '../../../theme/colors';
// import { weight } from '../../../theme/fonts';

export const KeyWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${'' /* border: 1px solid ${colors.greyLine}; */}
  ${'' /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3); */}
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border-left: 0;
  border-top: 0;
  background-color: ${(props) => props.backgroundColor};
  color: ${colors.primaryColor};

  &:active {
    color: ${colors.primaryColor};
  }

  .key {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
    ${'' /* -webkit-box-shadow: 2px 2px 5px ${colors.primaryColor}; */}
    -webkit-border-radius: 50% 50% 50% 50%;
    border: 1px solid ${colors.primaryColor};
    color: ${colors.greenGYGAS};

    &__item {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 25px;
      line-height: 29px;
      background-color: ${(props) => (props.isDisable !== false ? `${colors.primaryColor}` : `${colors.greenGYGAS}`)};
      color: ${(props) => (props.isDisable !== false ? `${colors.white}` : `${colors.primaryColor}`)};
      font-weight: ${(props) => (props.isDisable === false ? 'bolder' : '')};
      opacity: 1;
      ${'' /* border: 1px solid ${colors.primaryColorLight}; */}

      &:active {
        color: ${colors.greenGYGAS};
        font-weight: unset;
      }

      &:hover {
        background-color: ${colors.primaryColor};
        color: ${colors.backgroundColor};
        ${'' /* border: 1px solid ${colors.white}; */}
        ${
          '' /* -webkit-box-shadow: 1px 1px 5px ${colors.primaryColor};
        -webkit-border-radius: 50% 50% 50% 50%; */
        }
      }
    }
  }
  &:hover {
    background-color: ${colors.white};
    border: 1px solid ${colors.greyHover};
    color: ${(props) => (props.isDisable ? `${colors.primaryColor}` : `${colors.primaryColor}`)};
    cursor: ${(props) => (props.isDisable ? '' : `pointer`)};
    ${'' /* transition: all 0.3s ease-in-out; */}
  }
`;
