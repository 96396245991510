import React, { useState, useEffect } from 'react';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import { func, object } from 'prop-types';
import { IconBrandDeezer, IconLanguage, IconBrandSpeedtest, IconVolume, IconPlayerPlayFilled, IconGenderFemale, IconGenderMale } from '@tabler/icons-react';
import { VoiceSynthesizerWrapper } from './VoiceSynthesizer.style';
// import { IconPlayerPauseFilled } from '@tabler/icons-react';

// import { IconDeviceFloppy } from '@tabler/icons-react';

export const VoiceSynthesizer = ({ onDrop }) => {
  const [voices, setVoices] = useState([]);
  const [filteredVoices, setFilteredVoices] = useState([]);

  // const [, setAudioData] = useState(null);
  const [tempAudioUrl, setTempAudioUrl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('Spanish (Spain)');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedVoice, setSelectedVoice] = useState(null);

  const [text, setText] = useState('Bienvenido, escribe algo para convertirlo en audio...');
  const [pitch, setPitch] = useState(1);
  const [rate, setRate] = useState(1);
  const [volume, setVolume] = useState(50);

  const [isLoading, setIsLoading] = useState(false);
  const [, setIsPlaying] = useState(false);
  const [speechConfig, setSpeechConfig] = useState(null);

  const [langStatus, setLangStatus] = useState(false);
  const [genderStatus, setGenderStatus] = useState(false);
  const [genderMaleStatus, setGenderMaleStatus] = useState(false);
  const [genderFemaleStatus, setGenderFemaleStatus] = useState(false);
  const [voiceStatus, setVoiceStatus] = useState(false);
  const [speedStatus, setSpeedStatus] = useState(false);
  const [volumeStatus, setVolumeStatus] = useState(false);
  const [rateStatus, setRateStatus] = useState(false);

  const config = speechsdk.SpeechConfig.fromSubscription('99bde9706401434c9412e07512989441', 'francecentral');
  const synthesizer = new speechsdk.SpeechSynthesizer(config, null);

  // setSynthesizer(synth);s
  useEffect(() => {
    // Initialize speech config
    setSpeechConfig(config);

    // Get available voices using the modern method
    synthesizer
      .getVoicesAsync()
      .then((result) => {
        if (result && result.voices) {
          setVoices(result.voices);
          setFilteredVoices(result.voices);
          setSelectedVoice(result.voices[205]);
          setSelectedGender(result.voices[205].privGender === 1 ? 'mujer' : 'hombre');
          setSelectedLanguage('Spanish (Spain)'); // Set the first voice as the default selected voice
        }
      })
      .catch((error) => {
        console.error('Error getting voices:', error);
      });
    // .finally(() => {
    //   synthesizer.close();
    // });
  }, []);

  useEffect(() => {
    let filtered = [...voices];

    if (selectedLanguage) {
      filtered = filtered.filter((voice) => voice.localeName.toLowerCase().includes(selectedLanguage.toLowerCase()));
    }

    if (selectedGender || (!genderMaleStatus && !genderFemaleStatus)) {
      filtered = filtered.filter((voice) => {
        const voiceGender = voice.gender === 1 ? 'mujer' : 'hombre';
        return (
          (!genderMaleStatus && !genderFemaleStatus && voiceGender === 'mujer') || (!genderMaleStatus && !genderFemaleStatus && voiceGender === 'hombre') || voiceGender == selectedGender.toLowerCase()
        );
      });
    }

    setFilteredVoices(filtered);
  }, [selectedLanguage, selectedGender, voices, genderMaleStatus, genderFemaleStatus]);

  const languages = [...new Set(voices.map((voice) => voice.localeName))];

  const generateSpeech = async () => {
    if (!selectedVoice || !text || !speechConfig) return;

    setIsLoading(true);
    setIsPlaying(true);

    try {
      // Configure the voice
      speechConfig.speechSynthesisVoiceName = selectedVoice.shortName;

      // Create audio config for speaker output
      const audioConfig = speechsdk.AudioConfig.fromDefaultSpeakerOutput();

      // Create synthesizer
      const synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);

      // const ssmlText = `${text}`;
      // 'en-US-AvaMultilingualNeural';
      const ssmlText = `
      <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${selectedLanguage}%">
        <voice name="${selectedVoice.shortName}" effect="eq_car">
          <prosody pitch="${pitch}%" volume="${volume}%" rate="${rate}%">
            ${text}
          </prosody>
        </voice>          
      </speak>`;

      // Synthesize the speech
      synthesizer.speakSsmlAsync(
        ssmlText,
        (result) => {
          if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
            const audioDataBlob = new Blob([result.audioData], { type: 'audio/mp3' });

            // Create a temporary URL for the blob
            const tempUrl = URL.createObjectURL(audioDataBlob);
            setTempAudioUrl(tempUrl);
            // Create a temporary link element to trigger download
            // const downloadLink = document.createElement('audio__temp');
            // if (downloadLink) {
            //   downloadLink.href = tempUrl;
            //   downloadLink.download = 'audioFile__temp.mp3';
            // }

            const audioFile = new File([audioDataBlob], 'synthesized_speech.mp3', {
              type: 'audio/mp3',
              lastModified: new Date().getTime(),
            });
            // Trigger download
            // downloadLink.click();

            // Clean up by revoking the temporary URL
            // URL.revokeObjectURL(tempUrl);
            // setAudioData(audioDataBlob);
            onDrop(audioFile);
            console.log('Speech synthesis completed successfully');
            // synthesizer.close();
          } else {
            console.error('Speech synthesis failed:', result.errorDetails);
            synthesizer.close();
          }

          setIsLoading(false);
        },
        (error) => {
          console.error('Error:', error);
          setIsLoading(false);
          synthesizer.close();
        }
      );
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  // const handlePause = () => {
  //   if (synthesizer) {
  //     if (isPlaying) {
  //       synthesizerWindow.pause;
  //       // .then(() => {
  //       setIsPlaying(false);
  //       console.log('Speech paused');
  //       // })
  //       // .catch((err) => console.error('Error pausing speech:', err));
  //     } else {
  //       synthesizerWindow.resume;
  //       // .then(() => {
  //       setIsPlaying(true);
  //       console.log('Speech resumed');
  //       // })
  //       // .catch((err) => console.error('Error resuming speech:', err));
  //     }
  //   }
  // };

  // const handleSave = async () => {
  //   try {
  //     // Check if file already exists
  //     const fileExists = await checkFileExists(); // You'll need to implement this based on your storage method

  //     if (fileExists) {
  //       const isConfirmed = window.confirm('Ya tiene un fichero de audio anexado, ¿Desea sustituirlo?');

  //       if (!isConfirmed) {
  //         return;
  //       }
  //     }

  //     // Convert audio to base64
  //     const base64Data = audioData ? await convertToBase64(audioData) : localStorage.getItem('audioFile');

  //     // Save the file
  //     let action = { action: 'dropzone', value: base64Data };
  //     form(action, 'locution');
  //     // form({ action: 'dropzone', base64Data }, { form: 'locution', element: 'file' });
  //     setInputValue((prevState) => ({
  //       ...prevState,
  //       ['file']: base64Data,
  //     }));
  //     // setInputValue(base64Data);
  //     // await saveFile(base64Data);
  //   } catch (error) {
  //     console.error('Error saving file:', error);
  //   }
  // };

  // const convertToBase64 = (data) => {
  //   if (data) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(data);
  //       reader.onload = () => resolve(reader.result.split(',')[1]);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   } else {
  //     console.log('convertToBase64() data undefined or null');
  //   }
  // };

  // Example function to save file - implement according to your needs
  // const saveFile = async (base64Data) => {
  //   // Implementation depends on your storage method
  //   // Example using localStorage:
  //   localStorage.setItem('audioFile', base64Data);
  //   setAudioData(base64Data);
  //   // ['file']: base64Data,

  //   // Or if saving to server:
  //   /*
  //   await fetch('/api/save-audio', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ data: base64Data }),
  //   });
  //   */
  // };

  // Example function to check if file exists
  // const checkFileExists = async () => {
  //   // Implementation depends on your storage method
  //   // Example using localStorage:
  //   const fileExist = localStorage.getItem('audioFile') !== null ? localStorage.getItem('audioFile') : false;
  //   return fileExist;

  // };

  // const handleStop = () => {
  //   if (synthesizer) {
  //     synthesizer.close();
  //     // .then(() => {
  //     setIsPlaying(false);
  //     console.log('Speech stopped');
  //     // })
  //     // .catch((err) => console.error('Error stopping speech:', err));
  //   }
  // };

  function createFlagElement(countryCode) {
    const img = document.createElement('img');
    // Using flagcdn.com service
    img.src = `https://flagcdn.com/w20/${countryCode.substring(0, 2).toLowerCase()}.png`;
    img.className = 'flag-icon';
    // Add srcset for better resolution on retina displays
    img.srcset = `https://flagcdn.com/w40/${countryCode.substring(0, 2).toLowerCase()}.png 2x`;

    return img.src;
  }

  // const SaveButton = styled.button`
  //   padding: 8px 16px;
  //   border-radius: 5px;
  //   color: ${colors.primaryColor};
  //   border: 1px solid ${colors.backgroundColor};
  //   cursor: pointer;

  //   &:hover {
  //     border: 1px solid ${colors.primaryColor};
  //     transition: all 0.5s linear;
  //     box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
  //     border-radius: 5px;
  //   }
  // `;
  useEffect(() => {
    // Cleanup function to revoke the URL when component unmounts
    // or when tempAudioUrl changes
    return () => {
      if (tempAudioUrl) {
        URL.revokeObjectURL(tempAudioUrl);
      }
    };
  }, [tempAudioUrl]);

  return (
    <VoiceSynthesizerWrapper>
      <div className="voice-selector">
        <div className="filters_container"></div>
        <textarea id="textareaTTS" value={text} onChange={(e) => setText(e.target.value)} rows={4} cols={50} placeholder="Introduzca el texto para convertir a audio..." />
      </div>

      <div className="voice-details">
        <div className="details__item_Lamguage">
          <IconLanguage onClick={() => setLangStatus(!langStatus)} />
          <div className="filter_body">
            {langStatus && (
              <select className={'contain__select'} id="language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                <option value="">Todos los idiomas</option>
                {languages.map((language) => (
                  <option key={language} value={language}>
                    <img src={createFlagElement(language)} alt={language} />
                    {language}
                  </option>
                ))}
              </select>
            )}
            <span onClick={() => setLangStatus(!langStatus)}>{!langStatus && selectedLanguage}</span>
          </div>
        </div>
        <div className={'player_controls'}>
          <IconPlayerPlayFilled className={'button_play'} onClick={() => generateSpeech()} disabled={isLoading || !selectedVoice || !text}>
            {isLoading ? 'Generando...' : 'Probar'}
          </IconPlayerPlayFilled>

          {/* <IconPlayerPauseFilled className={'button_pause'} onClick={handlePause}>
            Pausa
          </IconPlayerPauseFilled> */}
          {/* {isPlaying && (
            <IconPlayerStopFilled className={'button_stop'} onClick={handleStop}>
              Detener
            </IconPlayerStopFilled>
          )} */}
          {/* Your existing voice synthesizer components */}

          {/* <SaveButton }>Guardar</SaveButton> */}
          {/* </IconDeviceFloppy> */}
        </div>

        <div className="details__item_Voice">
          {genderStatus && (
            <select
              className={'contain__select'}
              id="voice"
              value={selectedVoice?.shortName}
              onChange={(e) => {
                const voice = filteredVoices.find((v) => v.shortName === e.target.value);
                setSelectedVoice(voice);
              }}>
              <option value="">Seleccionar voz</option>
              {filteredVoices.map((voice) => (
                <option key={voice.shortName} value={voice.shortName}>
                  {`${voice.localName} (${voice.locale})`}
                </option>
              ))}
            </select>
          )}
          {!genderStatus ? (
            <span
              onClick={() => {
                setVoiceStatus(!voiceStatus);
                setGenderStatus(!genderStatus);
              }}>
              {selectedVoice?.displayName}
            </span>
          ) : null}
          {selectedGender === 'hombre' ? (
            <IconGenderMale
              onClick={() => {
                // setVoiceStatus(!voiceStatus);
                setGenderStatus(!genderStatus);
              }}
            />
          ) : (
            selectedGender === 'mujer' && (
              <IconGenderFemale
                onClick={() => {
                  // setVoiceStatus(!voiceStatus);
                  setGenderStatus(!genderStatus);
                }}
              />
            )
          )}
        </div>
        {genderStatus && (
          <div className="details__item_Gender">
            <div className="gender__container">
              <IconGenderMale
                className={'select__male' + (genderMaleStatus ? ' selected' : '')}
                onClick={() => {
                  // if (selectedVoice?.privGender === 1) {
                  // setVoiceStatus(!voiceStatus);
                  setGenderMaleStatus(!genderMaleStatus);
                  genderFemaleStatus && setGenderFemaleStatus(!genderFemaleStatus);
                  // }

                  setSelectedGender('hombre');
                }}
              />
              <IconGenderFemale
                className={'select_female' + (genderFemaleStatus ? ' selected' : '')}
                onClick={() => {
                  // if (genderFemaleStatus) {
                  // setVoiceStatus(!voiceStatus);
                  setGenderFemaleStatus(!genderFemaleStatus);
                  genderMaleStatus && setGenderMaleStatus(!genderMaleStatus);
                  // }
                  setSelectedGender('mujer');
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="voice-details_Up">
        <div className="details__item_Speed">
          <IconBrandSpeedtest onClick={() => setSpeedStatus(!speedStatus)} />

          {speedStatus && (
            <div className="wrapper">
              <input
                type="range"
                id="speed"
                name="speed"
                min="0"
                max="10"
                step="1"
                value={pitch / 2}
                onChange={(e) => {
                  setPitch(Number(e.target.value) * 2);
                }}
              />
            </div>
          )}
          <span onClick={() => setSpeedStatus(!speedStatus)}>{pitch + 'x'}</span>
        </div>
        <div className="details__item_Rate">
          <IconBrandDeezer onClick={() => setRateStatus(!rateStatus)} />
          {rateStatus && (
            <div className="wrapper">
              <input
                type="range"
                id="rate"
                name="rate"
                min="0"
                max="10"
                step="1"
                value={rate / 2}
                onChange={(e) => {
                  setRate(Number(e.target.value) * 2);
                }}
              />
            </div>
          )}
          <span onClick={() => setRateStatus(!rateStatus)}>{rate + 'x'}</span>
        </div>
        <div className="details__item_Volume">
          <span onClick={() => setVolumeStatus(!volumeStatus)}> {volume + '%'}</span>
          {volumeStatus && (
            <div className="wrapper">
              <input
                type="range"
                id="volume"
                name="volume"
                min="0"
                max="100"
                step="10"
                value={volume / 2}
                onChange={(e) => {
                  setVolume(Number(e.target.value) * 2);
                }}
              />
            </div>
          )}
          <IconVolume onClick={() => setVolumeStatus(!volumeStatus)} />
        </div>
      </div>
      {/* {tempAudioUrl && <a href={''} id="audio__temp" />} */}
      {tempAudioUrl && (
        <audio controls src={tempAudioUrl} className="voice__audio-player">
          Your browser does not support the audio element.
        </audio>
      )}
    </VoiceSynthesizerWrapper>
  );
};

VoiceSynthesizer.propTypes = { onDrop: func, reducerForm: object, setInputValue: func, form: func };
