import styled from 'styled-components';
import { colors } from '../../theme/colors';
// import { newFontSize, weight } from '../../theme/fonts';

export const VoiceSynthesizerWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 10px;
  color: ${colors.primaryColor};

  .gender__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: max-content;
    position: absolute;
    left: 403px;
    top: -110px;

    svg {
      width: 30px;
      height: 30px;
      padding: 3px;
    }

    .select__male,
    .select__female {
      &:hover {
        background-color: ${colors.greenGYGASLight};
        border: 1px solid ${colors.primaryColor};
        border-radius: 10px;
      }
    }

    .selected {
      background-color: ${colors.greenGYGAS};
      color: ${colors.primaryColor};
      border-radius: 10px;

      &:hover {
        border: 1px solid ${colors.primaryColor};
        border-radius: 10px;
      }
    }
  }

  .button__save {
    width: 100px;
    height: 35px;
    position: relative;
    top: -110px;
    left: 170px;
    border-radius: 10px;
    background-color: ${colors.primaryColorLight};
    color: ${colors.backgroundColor};

    &:hover {
      background-color: ${colors.primaryColor};
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .voice-selector {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
  }

  textarea {
    height: 100%;
    width: 440px;
    min-height: 230px;
    border-radius: 10px;
    padding: 20px;
    padding-top: 60px;
    ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}

    ${
      '' /* &:hover {
      -webkit-transition: all 0.5s linear;
      box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    } */
    }
  }

  .controls__container {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 40px;
      height: 30px;
    }
  }

  .voice-details {
    height: 100%;
    max-width: 450px;
    width: 100%;
    position: relative;
    top: -55px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 5px;
    color: ${colors.primaryColor};
    opacity: 1;
    display: flex;
    align-items: center;

    span {
      width: 100%;
      text-align: right;
      color: ${colors.primaryColor};
    }

    .filter_body {
      display: flex;
      width: 100%;
      ${'' /* gap: 10px; */}
      justify-content: flex-start;
      align-items: center;
      color: ${colors.primaryColor};
      background-color: ${colors.backgroundColor};

      span {
        text-align: left;
        color: ${colors.primaryColor};
      }
      .contain__select {
        width: 100%;
        max-width: 280px;
        border: none;
        align-self: baseline;
        grid-row-start: 2;
      }
    }

    .details__item_Lamguage {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.primaryColor};
      border-radius: 10px;
      flex: 2;

      svg {
        width: 35px;
        height: 35px;
        padding: 5px;
      }

      &:hover {
        cursor: pointer;
        border-radius: 5px;
      }
    }

    .details__item_Voice {
      width: 33%;
      height: 38px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${colors.primaryColor};
      border-radius: 10px;
      gap: 5px;
      flex: 2;

      span {
        color: ${colors.primaryColor};
        flex: 2;
      }

      svg {
        width: 35px;
        height: 35px;
        padding: 5px;

        ${
          '' /* &:hover {
          border: 1px solid ${colors.primaryColor};
          background-color: ${colors.greenGYGASLight};
          border-radius: 10px;
        } */
        }
      }

      &:hover {
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }

  .voice-details_Up {
    position: relative;
    top: -300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    max-width: 440px;
    width: 100%;

    .details__item_Volume {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: ${colors.primaryColor};
      border-radius: 10px;
      border: 1px solid ${colors.backgroundColor};
      padding: 5px;
      gap: 5px;

      &:hover {
        border-radius: 5px;
      }

      span {
        color: ${colors.primaryColor};
      }
    }

    .details__item_Speed {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: ${colors.primaryColor};
      border-radius: 10px;
      border: 1px solid ${colors.backgroundColor};
      padding: 5px;
      gap: 5px;

      span {
        color: ${colors.primaryColor};
      }

      &:hover {
        border-radius: 5px;
      }
    }

    .details__item_Rate {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: max-content;
      color: ${colors.primaryColor};
      border-radius: 10px;
      border: 1px solid ${colors.backgroundColor};
      padding: 5px;
      gap: 5px;

      span {
        color: ${colors.primaryColor};
      }

      &:hover {
        border-radius: 5px;
      }
    }
  }

  .filters_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    color: ${colors.primaryColor};

    select {
      width: 100%;
      height: 34px;
      border-radius: 5px;
      border: 1px solid ${colors.greyActive};
      display: flex;
      align-content: center;
      cursor: pointer;

      &:hover {
        border-color: ${colors.primaryColor};
      }
    }

    .filter_container {
      width: 100%;
      background-color: ${colors.backgroundColor};
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      svg {
        color: ${colors.primaryColor};
        width: 40px;
        height: 40px;
        padding: 3px;
      }
    }
  }

  .voice__audio-player {
    width: 220px;
    height: 35px;
    position: relative;
    top: -110px;
    right: unset;
  }

  .player_controls {
    color: ${colors.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 33%;
    border-radius: 10px;
    flex: 1;
  }

  .button_play,
  .button_pause,
  .button_stop {
    width: 35px;
    height: 35px;
    padding: 5px;

    &:hover {
      border-radius: 5px;
    }
  }

  .wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  input[type='range'] {
    width: 100%;
    margin: auto;
    -webkit-appearance: none;
    overflow: hidden;
    height: 20px;
    cursor: pointer;
    background-color: ${colors.greyLine};
    color: ${colors.primaryColorLight};
    border-radius: 10px;
  }

  ::-webkit-slider-runnable-track {
    background: #ddd;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-left: 1px;
    background-color: ${colors.greenGYGAS};
    border-radius: 50%;
    height: 16px;
    width: 1rem;
    ${'' /* border: 2px solid ${colors.primaryColor}; */}
  }

  input[type='range']::-webkit-slider-runnable-track {
    background-color: ${colors.secondaryColorLight};
    border-radius: 0.5rem;
    ${'' /* height: 0.5rem; */}
  }
  /*
 * 1. Set to 0 width and remove border for a slider without a thumb
 * 2. Shadow is negative the full width of the input and has a spread 
 *    of the width of the input.
 */
  ${
    '' /* -webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 40px;
    color: ${colors.primaryColorLight};
    box-shadow: -200px 0 0 200px dodgerblue; 
    border: 2px solid #999; 
  } */
  }

  ::-moz-range-track {
    height: 40px;
    background: #ddd;
  }

  ::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 20px; /* 1 */
    border: 3px solid #999; /* 1 */
    border-radius: 0 !important;
    box-shadow: -200px 0 0 200px dodgerblue;
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: dodgerblue;
  }

  ::-ms-thumb {
    background: #fff;
    border: 2px solid #999; /* 1 */
    height: 40px;
    width: 20px; /* 1 */
    box-sizing: border-box;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: #ddd;
    color: red;
    height: 40px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
`;
