import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const SearchWrapper = styled.div`
  height: 35px;
  color: ${colors.primaryColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 34px;
  ${'' /* padding: 5px; */}
  cursor: ${(props) => (props.active ? 'text' : 'pointer')};
  border: 1px solid ${(props) => (props.active ? `${colors.blueCheckbox}` : `unset`)};

  &:hover {
    cursor: pointer;
  }

  ${
    '' /* .user__contain__table {
    .search__container {
      height: 35px;
      position: absolute;
      right: 80px;
      color: ${colors.backgroundColor};

      input {
        width: ${(props) => (props.active ? '240px' : '0px')};
      }
    }
  } */
  }
  ${
    '' /* span {
    border-radius: 8px;
    width: 80.12px;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  } */
  }

  input {
    border: none;
    padding: 5px;
    padding-right: 5px;
    color: ${colors.black};
    font-weight: ${weight.medium};
    font-size: ${newFontSize.body};
    font-family: ${fontFamily.font};
    width: ${(props) => (props.active ? '110px' : '5px')};
    ${'' /* max-width: ${(props) => (props.active ? '282px' : '35px')}; */}
    background-color: unset;

    ${
      '' /* border-right: 0;
    border-left: 0; */
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.black};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.body};
      font-family: ${fontFamily.font};
    }
  }

  .search__icon {
    height: 100%;
    padding-left: 5px;
    ${'' /* border-right: 0; */}
  }

  .search__delete__icon {
    width: 35px;
    height: 100%;
    padding-right: 5px;
    color: ${colors.primaryColorLight};
  }
`;
