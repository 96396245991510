import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const TableInfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  ${'' /* margin-right: 0; */}
  height: 100%;
  overflow: auto;

  .userlogo__container {
    .user__logo {
      max-width: 45px;
      width: auto;
      min-width: auto;
      height: 20px;
    }

    .user__logo {
      width: 30px;
      height: 30px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      font-weight: bold;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #94ce0dff;
      color: #334155;
      border-radius: 50%;
    }
  }

  .icon-activated {
    color: ${colors.green};
    margin-left: 10px;
    stroke-width: 3;
  }

  .isDefault {
    max-width: 50px;
    min-width: unset;
  }

  .icon-deactivated {
    color: ${colors.red};
    margin-left: 5px;
    stroke-width: 3;
  }

  .iconDevices__column {
    min-width: unset;
    max-width: 50px;

    td {
      min-width: unset;
      max-width: 50px;
    }
  }
  .iconDevices__container {
    display: flex;
    gap: 5px;
    justify-content: center;
    min-width: unset;
  }

  .icon-fav-activated {
    color: ${colors.burlywood};
    ${'' /* margin-left: 15px; */}
  }

  .icon-fav-deactivated {
    color: ${colors.black};
    ${'' /* margin-left: 15px; */}
  }

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    background: ${colors.primaryColor};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  ${
    '' /* .last-td div:last-child {
    display: flex;
    align-items: right;
    width: 100%;
    justify-content: flex-end;
  } */
  }

  .tooltip {
    background: ${colors.primaryColor};
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    padding-bottom: 25px;
    text-align: left;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    text-align: left;
  }

  .tooltip-container {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: white;
    border-radius: 10px;
  }

  .tooltip-locution {
    width: 200px;
  }

  .tooltip-body {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    border-radius: 10px;
    background-color: none;
  }

  .edit-buttons {
    text-align: right;
  }

  .icons__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .icon__row {
      opacity: 0.7;
      border-radius: 5px;
      width: 30px;
      height: 30px;
      padding: 2px;

      &:hover {
        cursor: pointer;
        background-color: #0c3fc4;
        opacity: 1;
        border-radius: 5px;
        color: white;
      }
    }
  }

  .col__fullName {
    min-width: 230px;
  }

  table {
    ${'' /* width: 100%; */}
    margin-bottom: 80px;

    thead {
      tr {
        position: absolute;
        background-color: ${colors.backgroundColor};
        height: 50px;
        width: auto;
        top: 50px;
        padding-left: 8px;
        z-index: 9999;

        svg {
          color: white;
          margin-left: 9px;
        }

        ${'' /* .tooltip-column { */}
        ${'' /* min-width: 120px; */}
          ${
          '' /* color: black;
          width: auto;
        } */
        }
      }

      .tooltip-column_devices {
        width: 100%;
      }

      th:nth-child(6) {
        h3 {
          margin: auto;
          padding: 0;
        }

        .tooltip-column {
          min-width: 120px;
          width: 100%;
        }
      }
    }
    .SIPExtensionId {
      width: 40px;
      min-width: unset;
      text-align: center;

      .extensions__format {
        background-color: ${colors.primaryColor};
        border-radius: 20px;
        padding: 5px;
        color: ${colors.white};
      }
    }

    tbody {
      tr {
        position: relative;
        top: 45px;
        height: 50px;
        width: 100%;

        &:hover {
          cursor: pointer;
          background-color: ${colors.white};
        }
      }

      td {
        padding: 5px;
        border-left: none;
        line-height: 16px;
        letter-spacing: 0.25px;
        margin: auto;
      }

      .filepath {
        padding: 0;

        .tooltip-Locutions {
          display: flex;
          color: black;
          border-radius: 10px;
          height: 100%;
          width: 200px;

          audio {
            ${'' /* width: 135px; */}
            ${'' /* height: 20px; */}
            text-align: left;
          }
        }
      }
    }
  }

  th tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: bold;
    max-width: 120px;
  }

  ${
    '' /* td {
    text-align: left;
  } */
  }

  .icon-volumen {
    cursor: pointer;
    margin-left: 20px;
    width: 25px;
    height: 25px;
  }

  table th {
    font-size: ${newFontSize.button};
    color: ${colors.white};
    letter-spacing: 0.25px;
    font-weight: ${weight.medium};
    line-height: 16px;
    height: 46px;
    color: ${colors.primaryColor};

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  ${
    '' /* table tr {
    height: 35px;
    width: 100%;
    background-color: ${colors.backgroundColor};
    position: relative;
    top: 50px;

    &:last-child {
      text-align: right;
      height: 35px;
      background-color: ${colors.backgroundColor};
    }
  } */
  }

  table th:last-child {
    height: auto;
    ${'' /* width: 100%; */}
    ${'' /* border-right: none; */}
    ${'' /* border-radius: 10px; */}
  }

  table td:nth-child(2) {
    cursor: pointer;
    border-left: none;
  }

  table td:nth-child(3) {
    cursor: pointer;
    border-left: none;
    ${'' /* overflow: hidden; */}
    ${'' /* width: 100%; */}
  }

  table td:nth-child(1) {
    width: 40px;
    border-left: none;
    cursor: pointer;
  }

  .selected-row td:nth-child(2) {
    color: white;
    width: 20%;
    max-width: 40px;
  }

  td div:first-child {
    border-left: none;
    font-weight: 700;
    color: ${colors.primaryColors};
    border-color: ${colors.white};
    cursor: pointer;
    ${'' /* width: max-content; */}

    .tooltip__audio {
      padding: 5px;
      color: white;
      width: 100%;
      font-weight: normal;
      z-index: 700;
    }
  }
  .checkbox {
    margin: auto;
    padding: 0;
    min-width: 40px;

    .tooltip-column:first-child {
      width: 0px;
      justify-content: center;
    }
  }

  .spacer {
    width: 30px;

    &:last-child {
      width: 100%;
    }
  }

  .image-header {
    width: 20px;
    height: 20px;
  }

  .image-showPassword {
    &:hover {
      cursor: pointer;
    }
  }
`;
