import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const UsersScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: visible;
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  ${'' /* margin-bottom: 40px; */}

  .user__contain__table {
    position: relative;
    top: 50px;
    right: 50px;
    height: 270px;
    max-width: 520px;
    width: 100%;
    min-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 900;
    border-radius: 10px;
    ${'' /* overflow: auto; */}
    padding: 20px 40px 30px;
    background-color: ${colors.primaryColorLight};

    .navTable__selectedItems {
      color: ${colors.backgroundColor};
    }
    .search__container {
      height: 35px;
      position: absolute;
      right: 80px;
      color: ${colors.backgroundColor};

      .search__delete__icon {
        width: 35px;
        height: 100%;
        padding-right: 5px;
        color: ${colors.backgroundColor};
      }

      input {
        color: ${colors.primaryColor};
      }
    }

    .icon-dropDown {
      color: ${colors.white};
    }

    .icon_add_container {
      background-color: ${colors.primaryColorLight};
      color: ${colors.white};
      border-radius: 15px;
      border: 1px solid ${colors.secondaryColor};

      &:hover {
        color: ${colors.primaryColor};
        border: 1px solid ${colors.greenGYGAS};

        span {
          color: ${colors.primaryColor};
        }
      }
    }

    span {
      color: ${colors.white};
    }

    .nav__buttons__container {
      height: 50px;
    }

    .container__cards {
      top: 120px;
    }

    .left {
      width: 100%;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin: auto;
      gap: 20px;
      color: white;
      border-radius: 10px 10px 0px 0;

      .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        margin-left: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        ${'' /* border-radius: 10px 10px 0 0; */}
        color: ${colors.white};
        ${'' /* position: relative; */}
        margin-left: 10px;

        h3 {
          color: ${colors.white};
        }

        tr {
          position: absolute;
          ${'' /* left: 48px; */}
          top: 60px;
          width: 471px;
          z-index: 9;
          height: 40px;
          background-color: ${colors.primaryColorLight};
        }

        td {
          min-width: 40px;
        }

        tr th:nth-child(2) {
          border-left: none;
          font-weight: 700;
          cursor: pointer;
          min-width: 40px;
        }
      }

      tbody {
        .row {
          color: ${colors.primaryColor};
          background-color: ${colors.backgroundColor};
          border-bottom: 1px solid ${colors.greyHover};

          &:hover {
            background-color: ${colors.white};
          }

          .checkbox {
            min-width: 30px;
            margin: auto;
            padding-left: 10px;

            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      tr {
        position: relative;
        top: 45px;
        height: 30px;
        width: 100%;
      }
    }

    .navtable__container {
      width: auto;
      padding: 0;
      position: relative;
      top: 0;
      left: -15px;
      display: flex;
      justify-content: flex-start;
      gap: 0;
      ${'' /* margin: auto; */}
      color: white;
      border-radius: 10px 10px 0px 0;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    .tooltip-column {
      color: ${colors.primaryColor};
    }
    .numb__table {
      width: 60%;
    }

    .container .left {
      left: 285px;
      top: 60px;
    }

    .section__name {
      display: flex;
      align-items: center;
      height: 30px;
      color: ${colors.white};
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      width: max-content;
    }
  }

  .contain__table {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 65px;
    padding-left: 20px;
    overflow: scroll;
  }

  .users-container {
    background-color: ${colors.backgroundColor};
    ${'' /* border-rad0px; */}
  }

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .userlogo__container,
  .fullName {
    max-width: 45px;
    width: auto;
    min-width: auto;
    height: 20px;
  }

  #firstname__0 {
    width: 100%;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    border-radius: 10px 0 0 0;
    padding: 20px 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      color: ${colors.primaryColor};
    }
  }

  #lastname__1 {
    width: 100%;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    border-radius: 0 10px 0 0;
    padding: 20px 20px;
    grid-column-start: 3;
    grid-column-end: 4;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      color: ${colors.primaryColor};
    }
  }

  #secondname__2 {
    width: 100%;
    background-color: ${colors.greenGYGAS};
    padding: 0px 20px;
    grid-column-start: 2;
    grid-row-start: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${'' /* box-shadow: rgb(0, 0, 0) 2px 2px 4px 0px; */}

    span {
      color: ${colors.primaryColor};
    }
  }

  #email__3 {
    width: 100%;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    padding: 0px 20px;
    grid-column-start: 3;
    grid-row-start: 2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

    span {
      color: ${colors.primaryColor};
    }

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }

  #companyId__4 {
    width: 210px;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    padding: 0 20px;
    z-index: 9999;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px -4px; */}
    z-index: 9999;

    span {
      color: ${colors.primaryColor};
    }

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }

  #departmentId__5 {
    width: 240px;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    padding: 0 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      color: ${colors.primaryColor};
    }

    .contain__select {
      width: 100px;
      min-width: unset;
    }
  }

  #category__8 {
    height: 100%;
    background-color: ${colors.greenGYGAS};
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    padding: 0 20px;
    z-index: 9998;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

    span {
      color: ${colors.primaryColor};
    }

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }

    .contain__select {
      width: 100px;
      min-width: unset;
    }
  }

  #SIPExtension__7 {
    width: 100%;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    padding: 0 20px;
    z-index: 9998;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

    span {
      color: ${colors.primaryColor};
    }

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }

  #captureId__6 {
    width: 100%;
    height: 100%;
    background-color: ${colors.greenGYGAS};
    padding: 0 20px;
    border-radius: 0 0 10px 10px;
    z-index: 9998;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 5;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 9998;
    font-size: 16px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

    span {
      color: ${colors.primaryColor};
    }

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }
  ${
    '' /* width: 100%;
    height: 100%;
    font-size: 16px;
    background-color: ${colors.greenGYGAS};
    padding: 0px 20px;
    border-radius: 0 0 10px 10px;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 5;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px;
    border-bottom: 2px solid #526581;

    &:hover {
      border-bottom: 0;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    }
    .contain__select {
      width: 150px;
    }
  } */
  }

  #roleId__11,
  #accesslevelId__12 {
    width: 100%;
    height: 100%;
    padding: 10px 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid #989292;

    background-color: ${colors.primaryColorLight};

    .textRequired {
      color: white;
    }
    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px 0 ${colors.primaryColor};
      transform: scale(1.05);
    } */
    }
  }

  #roleId__11 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    border-radius: 0px 0px 0 0;
    border-right: none;
    ${'' /* box-shadow: rgb(0, 0, 0) 2px 2px 4px 0px; */}
    ${'' /* box-shadow: 9px 4px 7px 0 rgba (0, 0, 0, 70%); */}
  }

  #accesslevelId__12 {
    border-radius: 0px 10px 0px 0;
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    border-left: none;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}
    ${'' /* box-shadow: 9px 4px 7px 0 rgba (0, 0, 0, 70%); */}
  }

  #voicemailPIN__14,
  #isVoicemail__13,
  #isvoicemailEmail__15 {
    background-color: ${colors.primaryColorLight};
    color: ${colors.white};
    padding: 10px 20px 0px;
    height: 100%;
    border: 1px solid #989292;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

    .textRequired {
      color: white;
    }
    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }

    input {
      height: 35px;
    }
  }
  #isvoicemailEmail__15 {
    width: 100%;
    left: -200px;
    border-radius: 0 0px 0px 0px;
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 2;
    border-left: none;
  }

  #isVoicemail__13 {
    width: 100%;
    border-radius: 0px 0px 0 0px;
    grid-column-start: 5;
    grid-row-start: 2;
    border-right: none;

    input {
      width: 115px;
    }
  }

  #voicemailPIN__14 {
    width: 100%;
    padding: 10px 20px 10px;
    grid-column-start: 6;
    grid-row-start: 2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: none;

    input {
      width: 50px;
      height: 30px;
    }
  }

  #Seguridad,
  #Avisos,
  #Desvios,
  #ServiciosExternos {
    svg {
      padding: 5px;
      ${'' /* background-color: ${colors.primaryColorLight}; */}

      border-radius: 5px 0 0 5px;
      color: ${colors.primaryColorLight};
    }
  }

  #Seguridad {
    height: 100px;
    width: 100%;
    text-align: right;
    font-weight: bold;
    grid-column-start: 4;
    grid-row-start: 1;
  }

  #Avisos {
    height: 80px;
    width: 100%;
    text-align: right;
    font-weight: bold;
    grid-column-start: 4;
    grid-row-start: 2;
  }

  #Desvios {
    width: 100%;
    height: 80px;
    text-align: right;
    font-weight: bold;
    grid-column-start: 4;
    grid-row-start: 3;
  }

  #ServiciosExternos {
    width: 100%;
    height: 80px;
    text-align: right;
    font-weight: bold;
    ${'' /* padding: 10px; */}
    grid-column-start: 4;
    grid-row-start: 4;
  }
  #isCallForward__16,
  #callForward__17,
  #isNotAnswer__18,
  #notAnswer__19 {
    padding: 10px 20px;
    height: 100%;
    width: 100%;
    border: 1px solid #989292;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}
    background-color: ${colors.primaryColorLight};

    .textRequired {
      color: white;
    }
    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }

  #isCallForward__16 {
    grid-column-start: 5;
    grid-row-start: 3;
    width: 100%;
    border-radius: 0px 0px 0 0px;
    border-right: none;

    input {
      width: 115px;
      height: 30px;
    }
  }

  #isNotAnswer__18 {
    grid-column-start: 7;
    grid-row-start: 3;
    width: 100%;
    border-right: none;

    input {
      width: 115px;
      height: 30px;
    }
  }

  #callForward__17 {
    grid-column-start: 6;
    grid-row-start: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: none;
    border-right: none;

    input {
      width: 50px;
      height: 30px;
    }
  }

  #notAnswer__19 {
    grid-column-start: 8;
    grid-row-start: 3;
    width: 100%;
    border-radius: 0 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: none;
    ${
      '' /* span {
      position: relative;
      left: -10px;
      width: 50px;
    } */
    }
    input {
      height: 30px;
      width: 50px;
    }
  }

  #isRainbow__20,
  #isTeams__21 {
    height: 100%;
    width: 100%;
    padding: 8px 20px 10px;
    ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}
    background-color: ${colors.primaryColorLight};

    .textRequired {
      color: white;
    }
    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }
  }

  #isTeams__21 {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    border-radius: 0 0px 10px 0px;
  }

  #isRainbow__20 {
    width: 100%;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    border-radius: 0px 0 0 10px;
    gap: 10px;
    border-left: 1px solid rgb(130, 130, 130);
  }

  .user__logo {
    width: 30px;
    height: 30px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
    border-radius: 50%;
  }

  .users__screen {
    width: 100%;
    height: 100%;
    ${
      '' /* border: 1px solid ${colors.primaryColor};
    background-color: ${colors.backgroundColor}; */
    }
    ${'' /* border-radius: 15px; */}
  }

  .stepForm {
    width: 100%;
    margin: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    /* max-width: 600px; */
    row-gap: 13px;
    row-gap: 20px;
    margin: auto;
    max-width: unset;

    &__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      ${'' /* text-align: left; */}
      color: #0c3fc4;
      font-size: 14px;
      font-weight: bold;
      ${'' /* top: 50px; */}
      margin: auto;
      width: 100%;
    }
  }

  .form__title {
    height: 55px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    text-align: left;
    color: ${colors.black};
    font-weight: bold;
    margin: auto;
    width: 100%;
    margin-left: 130px;

    .form__user__logo {
      font-size: 40px;
      ${'' /* margin-top: unset; */}
    }
    .title__Form_title {
      margin-top: 35px;
      margin-left: 140px;
    }
  }

  ${
    '' /* .title__Form_title {
      color: ${colors.backgroundColor};
    } */
  }

  .stepForm__contain-typeInput {
    gap: 20px;
    background-color: ${colors.white};
    box-shadow: unset;
    padding-top: unset;
    border-radius: 15px;

    .basicRow {
      grid-column: span 1;
    }

    .contain__select {
      width: 100%;
      min-with: 210px;
    }

    .contain__input {
      min-with: 210px;
    }

    .input-disabled {
      width: 250px;
      background-color: hsl(0, 0%, 95%);
      border: 0;
    }
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
