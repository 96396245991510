import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ToggleSwitchWrapper, SecondVersionWrapper } from './ToggleSwitch.style';
import { colors } from '../../theme/colors';
import { bool, func, object, string } from 'prop-types';
import { useStore } from 'react-redux';
import useSettings from '../../hooks/useSettings';

export const ToggleSwitch = ({ color, toggleSwitch, data, version = false }) => {
  const { getState } = useStore();

  const [isOn, setIsOn] = useState(0);
  const { isTeams, isRainbow } = getState()['getConfigReducer'];

  const { getSettingsData } = useSettings();

  useEffect(() => {
    if (isTeams === undefined || isRainbow === undefined) getSettingsData();
    if (data.defaultValues === 1 || data.defaultValues === true) {
      setIsOn(1);
    } else {
      setIsOn(0);
    }

    if (data.ref?.form !== 'config') {
      if ((data.ref?.element === 'isTeams' && isTeams === 0) || (data.ref?.element === 'isRainbow' && isRainbow === 0)) {
        setIsOn(0);
      }
    }
  }, [data]);

  // const spring = {
  //   type: 'spring',
  //   stiffness: 700,
  //   damping: 30,
  // };

  const toggleDescription = () => {
    const content = 'Consultar Preferencias';

    // if (data.ref?.form !== 'config') {
    // if ((data.ref?.element === 'isTeams' && !isTeams) || (data.ref?.element === 'isRainbowelp' && !isRainbow)) {
    // content = ;
    // } else {
    //   content = data.value;
    // }
    // }

    const newDescription = <span className={'valueToggle__disabled'}>{data.ref?.form !== 'config' ? content : data.value}</span>;

    return newDescription;
  };

  if (!version)
    return (
      <ToggleSwitchWrapper color={color}>
        <motion.div
          className={'switch'}
          data-ison={
            (data.ref?.form !== 'config' && data.ref?.element === 'isTeams' && isTeams === 0) || (data.ref?.form !== 'config' && data.ref?.element === 'isRainbow' && isRainbow === 0)
              ? false
              : isOn === 1
                ? true
                : false
          }
          onClick={
            (data.ref?.form !== 'config' && data.ref?.element === 'isTeams' && isTeams === 0) || (data.ref?.form !== 'config' && data.ref?.element === 'isRainbow' && isRainbow === 0)
              ? () => {}
              : () => toggleSwitch(data, isOn, setIsOn)
          }
          initial={{ background: 'white' }}
          exit={{ y: 0 }}
          animate={isOn ? { background: color } : { background: colors.greyBorder }}>
          <div
            className="handle"
            // layout
            // transition={spring}
            // exit={{ y: 0 }}
            data-ison={
              (data.ref?.form !== 'config' && data.ref?.element === 'isTeams' && isTeams === 0) || (data.ref?.form !== 'config' && data.ref?.element === 'isRainbow' && isRainbow === 0) ? false : isOn
            }
          />
        </motion.div>
        {(data.ref?.element === 'isTeams' && isTeams === 0) || (data.ref?.element === 'isRainbow' && isRainbow === 0) ? toggleDescription() : null}
      </ToggleSwitchWrapper>
    );
  else
    return (
      <SecondVersionWrapper>
        {/* <motion.div className="container" onClick={() => toggleSwitch(data, isOn, setIsOn)}> */}
        <div className="container" onClick={() => toggleSwitch(data, isOn, setIsOn)}>
          <p className={isOn && 'selected'}>Locución</p>
          <p className={!isOn && 'selected'}>Grabación</p>
        </div>
      </SecondVersionWrapper>
    );
};

ToggleSwitch.propTypes = { isDisabled: bool, color: string, toggleSwitch: func, data: object, isActive: object, version: bool };
