import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const ContainerCalendar = styled.section`
  width: fit-content;
  margin-left: 10%;

  .header {
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 12px;

    button {
      border: none;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      //transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #7f40a6;
        color: white;
      }
    }
  }

  .months_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: flex-end;
    gap: 20px;
    font-size: 9px;

    table {
      background-color: ${colors.greyLine};
      ${'' /* width: 100%; */}
      border-radius: 15px;
    }
  }

  .edit_container {
    width: 100%;

    // background-color: ${colors.greyLine};
  }

  .month_name {
    color: black;
    background-color: white;
    font-weight: bold;

    border-radius: 15px 15px 0 0;
  }
  .week_buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
    }
  }

  .edit_months {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    gap: 20px;
    font-size: 9px;
  }
  table {
    background-color: ${colors.greyLine};
    border-radius: 10px;
  }
`;
