import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateNumberingList, modalNumberingList } from '../../../data/dataNumbering';
import useApplications from '../../../hooks/useApplications';
// import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import usePreferences from '../../../hooks/usePreferences';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { func, string } from 'prop-types';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { useDispatch } from 'react-redux';
import { isModalOpenActionCreator, selectedDocActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
import { ListNumberModalWrapper } from './ListNumberModalFormScreen.style';

export const ListNumberModalFormScreen = ({ crudType, onSave }) => {
  const { listNumberingForm, arrayModalExtNumbHook, updateNumberingListModal, formListNumberingReducer, createNumberingListModal, getNumberingList } = useNumbering();
  const { getCalendar, calendarArrayHook, scheduleArrayHook, getScheduleDB } = usePreferences();
  const { selectedDoc, isModalOpen } = useAppSelector((state) => state.system);
  const { getApplicationsLocutions, arrayLocutionsHook, getDestination } = useApplications();

  const { typeSwal } = useSwal();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const [newCrudType, setNewCrudType] = useState(crudType);

  const dispatch = useDispatch();
  const location = useLocation();

  const isModalForm = newCrudType === 'editRule' || newCrudType === 'createRule';

  const data = modalNumberingList;

  useEffect(() => {
    getCalendar();
    getScheduleDB();
    getApplicationsLocutions();
    getDestination();
    // setSearch([]);

    // listNumberingForm(
    //   { action: 'clearForm' },
    //   {
    //     form: 'numberingList',
    //   }
    // );
  }, []);

  useEffect(() => {
    // if (newCrudType === 'editRule') {
    // const newLocu = arrayLocutionsHook.find((itemLocu) => itemLocu.description === selectedDoc?.Locucion);

    listNumberingForm(
      {
        action: 'input',
        value: selectedDoc?.idRule || formListNumberingReducer?.idRule || formListNumberingReducer.id,
      },
      {
        form: 'numberingList',
        element: 'idRule',
      }
    );

    // listNumberingForm(
    //   {
    //     action: 'input',
    //     value: selectedDoc?.id || formListNumberingReducer.id || formListNumberingReducer?.id,
    //   },
    //   {
    //     form: 'numberingList',
    //     element: 'id',
    //   }
    // );

    listNumberingForm(
      {
        action: 'select-option',
        value: {
          value: selectedDoc?.soundPrompt ? selectedDoc?.soundPrompt?.id : arrayLocutionsHook[0]?.id,
          label: selectedDoc?.soundPrompt ? selectedDoc?.soundPrompt?.description : arrayLocutionsHook[0]?.description,
        },
      },
      {
        form: 'numberingList',
        element: 'soundPrompt',
      }
      // {}
    );

    // const newCal = getCalendarInfo(selectedDoc?.calendar, calendarArrayHook);
    listNumberingForm(
      {
        action: 'select-option',
      },
      {
        form: 'numberingList',
        element: 'calendar',
      },
      selectedDoc?.calendar ? selectedDoc?.calendar : { value: scheduleArrayHook[0][0]?.Schedule.id, label: scheduleArrayHook[0][0]?.Schedule.name }
    );

    listNumberingForm(
      {
        action: 'select-option',
        value: {
          value: selectedDoc?.schedule ? selectedDoc?.schedule?.value : arrayLocutionsHook[0]?.schedule,
          label: selectedDoc?.schedule ? selectedDoc?.schedule?.label : arrayLocutionsHook[0]?.schedule,
        },
      },
      {
        form: 'numberingList',
        element: 'schedule',
      }
    );

    // const destinationType = optionsDestination.find((item) => item.label === selectedDoc?.TipoDestino);

    listNumberingForm(
      {
        action: 'select-option',
        value: {
          value: selectedDoc?.destinationType ? selectedDoc?.destinationType?.id : arrayModalExtNumbHook[0]?.destinationTypeId,
          label: selectedDoc?.destinationType ? selectedDoc?.destinationType?.label : arrayModalExtNumbHook[0]?.DestinationType?.name,
        },
      },
      {
        form: 'numberingList',
        element: 'destinationType',
      }
      // selectedDoc?.destination
    );
    listNumberingForm(
      {
        action: 'select-option',
        value: {
          value: selectedDoc?.destinationId ? selectedDoc?.destinationId : arrayModalExtNumbHook[0]?.destinationId,
          label: selectedDoc?.Destino ? selectedDoc?.Destino : arrayModalExtNumbHook[0]?.DestinationType.name,
        },
      },
      {
        form: 'numberingList',
        element: 'destination',
      }
      // newDest[0]

      // selectedDoc?.destinationType
    );

    listNumberingForm(
      {
        action: 'toggle',
      },
      {
        form: 'numberingList',
        element: 'isDefault',
      },
      selectedDoc?.isDefault ? selectedDoc?.isDefault : arrayModalExtNumbHook[0]?.isDefault
    );
    // const getDestinationInfo = (destinationId, destinationTypeId) => {
    //   destinationId = destinationId?.length ? destinationId : [destinationId[0]];
    //   destinationTypeId = destinationTypeId?.length ? destinationTypeId : [destinationTypeId];

    //   // if (import.meta.env.VITE_DEBUG === 'true') console.log(destinationField);

    //   let newArray = [];

    //   destinationId.forEach((dest, index) => {
    //     const idType = optionsDestination[destinationTypeId[index]];

    //     let arrayToSearch = [];

    //     switch (idType?.ref || ivr) {
    //       case 'queue':
    //         arrayToSearch = queues;
    //         break;
    //       case 'conference':
    //         arrayToSearch = conferences;
    //         break;
    //       case 'voicemail':
    //         arrayToSearch = mailboxes;
    //         break;
    //       case 'ivr':
    //         arrayToSearch = ivr;
    //         // destinationId = destinationId ? destinationId : 178;
    //         break;
    //       default:
    //         arrayToSearch = mailboxes;
    //         break;
    //     }
    //     destinationId = destinationId ? destinationId : arrayToSearch[0].id;
    //     const newValue = arrayToSearch?.length ? arrayToSearch?.find((item) => item?.id === destinationId[index]) : '';
    //     // return newValue.map((newDestination) => {
    //     newArray.length > 0 ? newArray.push({ value: newArray.length + 1, id: newValue?.id, label: newValue?.name ? newValue.name : newValue?.extension }) : null;
    //     // });
    //   });
    //   return newArray;
    // };

    // const newDest = getDestinationInfo(selectedDoc?.destinationId, selectedDoc?.destinationType.id);

    // listNumberingForm(
    //   {
    //     action: 'multiselect',
    //   },
    //   {
    //     form: 'numberingList',
    //     element: 'calendars',
    //   },
    //   selectedDoc?.calendars
    // );

    //   location.state &&
    //     Object.keys(location.state).map((item) =>
    //       data.steps?.map((item2) =>
    //         item2.input?.map((item3) => {
    //           if (item === item3.ref?.element) {
    //             return listNumberingForm(
    //               {
    //                 action: item3.cardType,
    //                 value: location.state[item],
    //               },
    //               {
    //                 form: item3.ref.form,
    //                 element: item3.ref.element,
    //               }
    //             );
    //           } else {
    //             return null;
    //           }
    //         })
    //       )
    //     );
    // }
    // else {
    //   location.state &&
    //     Object.keys(location.state).map((item) =>
    //       data.steps?.map((item2) =>
    //         item2.input?.map((item3) => {
    //           if (item === item3.ref?.element) {
    //             // item3.defaultValues = '';
    //             // return listNumberingForm(
    //             //   {
    //             //     action: item3.cardType,
    //             //     value: '',
    //             //   },
    //             //   {
    //             //     form: item3.ref.form,
    //             //     element: item3.ref.element,
    //             //   }
    //             // );
    //           } else {
    //             return null;
    //           }
    //         })
    //       )
    //     );
    // }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof formListNumberingReducer === 'object' && arrayLocutionsHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            switch (item2.ref.element) {
              case 'calendar':
                item2.options = [];
                calendarArrayHook.forEach((itemCalendar) => {
                  item2.options.push({ value: itemCalendar[0]?.CalendarName.id, label: itemCalendar[0]?.CalendarName.name });
                });
                break;
              case 'schedule':
                item2.options = [];
                scheduleArrayHook.forEach((itemSched) => {
                  item2.options.push({ value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name });
                  // const newCal = { value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name };
                });
                break;
              case 'soundPrompt':
                item2.options = [];
                arrayLocutionsHook.forEach((itemSound) => {
                  item2.options.push({ value: itemSound?.id, label: itemSound?.description });
                  // const newCal = { value: itemSched[0]?.Schedule.id, label: itemSched[0]?.Schedule.name };
                });
                break;
              default:
            }
          });
        });

        if (typeof formListNumberingReducer === 'object') {
          if (
            ((newCrudType === 'edit' || newCrudType === 'editRule') && (formListNumberingReducer.id === null || formListNumberingReducer.id !== location.state?.id) && selectedDoc.id === null) ||
            selectedDoc.id === ''
          ) {
            setLoadingCounter(loadingCounter + 1);
          } else if (newCrudType === 'editRule') {
            data.steps.map((item) => {
              item.input?.map((item2) => {
                let newValue = {};
                switch (item2.ref.element) {
                  case 'id':
                    item2.defaultValues = selectedDoc?.id;
                    break;
                  case 'idRule':
                    item2.defaultValues = selectedDoc?.idRule;
                    break;
                  case 'calendar':
                    newValue = arrayModalExtNumbHook.find((itemCal) => itemCal.CalendarName.name === selectedDoc?.Calendario) || {};
                    item2.defaultValues = { value: newValue.CalendarName.id, label: newValue.CalendarName.name };
                    break;
                  case 'soundPrompt':
                    item2.defaultValues = { value: selectedDoc?.soundPrompt?.id, label: selectedDoc?.soundPrompt?.description };
                    break;
                  case 'destination':
                    item2.defaultValues = { value: selectedDoc?.destinationId, label: selectedDoc?.Destino };
                    break;
                  case 'destinationType':
                    item2.defaultValues = { value: selectedDoc?.destinationType?.id, label: selectedDoc?.destinationType?.label };
                    break;
                  case 'schedule':
                    item2.defaultValues = selectedDoc?.schedule;
                    break;
                  case 'isDefault':
                    item2.defaultValues = selectedDoc?.isDefault;
                    break;
                  default:
                    item2.defaultValues = '';
                    break;
                }

                setDataSave(true);
                return item2;
              });
              return item;
            });
            setLoading(false);
          } else if (newCrudType === 'createRule') {
            data.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'id':
                    item2.defaultValues = selectedDoc.id;
                    break;

                  case 'calendar':
                    item2.defaultValues = selectedDoc.Calendario;
                    break;
                  case 'soundPrompt':
                    item2.defaultValues = selectedDoc.soundPrompt;
                    break;
                  case 'destination':
                    item2.defaultValues = selectedDoc?.destination || formListNumberingReducer?.destinationType;
                    break;
                  case 'destinationType':
                    item2.defaultValues = formListNumberingReducer?.destination
                      ? formListNumberingReducer?.destination
                      : { value: selectedDoc?.destinationType?.id, label: selectedDoc?.destinationType?.label };
                    break;
                  case 'schedule':
                    item2.defaultValues = selectedDoc.schedule;
                    break;
                  case 'isDefault':
                    item2.defaultValues = selectedDoc.isDefault;
                    break;
                  default:
                    item2.defaultValues = '';
                    break;
                }
                return item2;
              });
              return item;
            });
            setLoading(false);
          }
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [newCrudType, loadingCounter]);

  const handleEditRule = (item) => {
    setNewCrudType('editRule');
    dispatch(selectedDocActionCreator(item.original));

    dispatch(isModalOpenActionCreator(true));
  };

  // const dataRules = () => {
  //   const arrayRules = [];
  //   // location.state &&      location.state
  //   selectedDoc?.calendars?.forEach((row, index) => {
  //     const newRow = {
  //       id: row.id,
  //       Calendario: row?.label,
  //       Horario: selectedDoc.schedules[index]?.label,
  //       Locucion: selectedDoc.soundPrompts[index]?.label,
  //       // TipoDestino: ,
  //       Destino: selectedDoc.destinationsType[index]?.label + ': ' + selectedDoc.destinations[index]?.label,
  //       isDefault: selectedDoc?.isDefaults && selectedDoc?.isDefaults[index] !== undefined ? selectedDoc?.isDefaults[index] : '',
  //     };
  //     row.label ? arrayRules.push(newRow) : null;
  //   });
  //   return arrayRules;
  // };
  // const template = [];

  // const arrayRules = dataRules();

  // const option = arrayRules.map(({ Calendario, Horario, Locucion }) => ({
  //   Calendario,
  //   Horario,
  //   Locucion,
  // }));

  // const [isEditMode, setIsEditMode] = useState(false);

  // const handleOnClickOut = (event) => {
  //   event.preventDefault();
  // };

  const handleOnCancel = () => {
    isModalOpen ? dispatch(isModalOpenActionCreator(false)) : history.back();
  };

  // const handleOnClickIn = (event) => {
  //   event.preventDefault();
  // };

  // const handleOpenFormEdit = (item) => {
  //   dispatch(selectedDocActionCreator(item.original));
  //   dispatch(isModalOpenActionCreator(true));
  // };

  // const handleOpenFormCreate = () => {
  //   dispatch(isModalOpenActionCreator(true));
  //   setNewCrudType('createRule');
  // };

  const onSubmit = async () => {
    setDataSave(true);
    isModalForm ? dispatch(isModalOpenActionCreator(false)) : null;
    if (newCrudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', createNumberingListModal);
    } else if (newCrudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingListModal);
    } else if (isModalForm) {
      // handleAddRule(location.state);
      if (newCrudType === 'createRule') {
        typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', createNumberingListModal);
      } else {
        typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingListModal);
        dispatch(isModalOpenActionCreator(false));
        onSave();
        //
      }
    }
  };

  return (
    <ListNumberModalWrapper className={'modal__container'} id="modalListNumber">
      {/* !isModalOpen ? 'form__container' : 'modal__container'}> */}
      {/* onClick={isModalForm ? handleOnClickOut : null}> */}
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          arrScreen={['Modal Numeración externa']}
          data={data}
          onSubmit={onSubmit}
          onCancel={handleOnCancel}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={newCrudType}
          onEditRule={handleEditRule}
          // maxwidth={'1000px'}
          reducerForm={formCreateNumberingList}
          // isEditMode={isEditMode}
        />
      )}
    </ListNumberModalWrapper>
  );
};

ListNumberModalFormScreen.propTypes = { crudType: string, onSave: func };
