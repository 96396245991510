import { useEffect, useState } from 'react';
import React from 'react';
import { array, string } from 'prop-types';
import {
  IconAlertCircle,
  // IconEdit,
  IconMail,
  IconNumber,
  IconPhone,
  IconStack,
  // IconUser,
  IconMicrophone,
  IconTableShortcut,
  IconPlaylist,
  IconLicense,
  // IconPlus,
  IconCalendar,
  IconApiApp,
  IconSchema,
  IconTerminal,
  IconBrandMedium,
  IconHome,
  IconBoxPadding,
  IconArrowWaveRightUp,
  IconMicrophone2,
  IconWorldWww,
  IconCoinMonero,
  IconSpeakerphone,
  IconGridDots,
  IconDevices,
  IconListNumbers,
} from '@tabler/icons-react';
import { useStore } from 'react-redux';
import { formCategories } from '../../../data/dataOutbound';

export const FormTitle = ({ type, formTitle }) => {
  const { getState } = useStore();

  const {
    queueFormReducer,
    ivrFormReducer,
    mailboxesFormReducer,
    conferenceFormReducer,
    shortMarkingFormReducer,
    songWaitingFormReducer,
    usersFormReducer,
    domainsFormReducer,
    EMFormAlertsReducer,
    arrayNumbering,
    formExtensionsReducer,
    whitelistFormReducer,
    companyFormReducer,
    objectCreateAPPFormLocutions,
    formListNumberingReducer,
    formPlantilla,
    formModelo,
    formMarcas,
    calendarFormReducer,
    arrayEMFormListExtensions,
    categoriesGroupFormReducer,
  } = getState();

  const [title, setTitle] = useState(type === 'createRule' ? 'Crear nueva regla' : formTitle[0] || '');
  const [Icon, setIcon] = useState('');
  // const [Icon2, setIcon2] = useState('');
  // const { isModalOpen } = useAppSelector((state) => state.system);

  const setFormTitle = () => {
    if (type === 'createRule') {
      setTitle('Nuevo');
      setIcon(<IconLicense className="icon__Form_title" />);
    } else if (type === 'editRule') {
      setTitle('Editar regla');
      setIcon(<IconLicense className="icon__Form_title" />);
    } else {
      const strItemTitle = title;
      const strType = strItemTitle.slice(-1) !== 'o' && strItemTitle.slice(-1) !== 'a' && strItemTitle.slice(-1) !== 'n' ? 'a' : strItemTitle.slice(-1) === 'n' ? 'o' : strItemTitle.slice(-1);
      const newLabel = 'Nuev' + strType + ' ';
      const isCreate = type === 'create' || type === 'createUserDispo';
      const firstWord = isCreate ? newLabel : strItemTitle === 'assignUserDispo' ? 'Asignar dispositivo a' : strType;
      // let newUserName = '';

      switch (strItemTitle) {
        case 'Usuario':
          setTitle(isCreate ? firstWord + title : usersFormReducer.firstname ? usersFormReducer.firstname + ' ' + usersFormReducer.lastname : 'Nuevo');
          // setIcon(<IconUser className="icon__Form_title" />);
          break;
        case 'Alerta':
          setTitle(isCreate ? firstWord + strItemTitle : EMFormAlertsReducer.name);
          setIcon(<IconAlertCircle className="icon__Form_title" />);
          break;
        case 'Cola':
          setTitle(isCreate ? firstWord + strItemTitle : queueFormReducer.name);
          setIcon(<IconStack className="icon__Form_title" />);
          break;
        case 'Número':
          setTitle(isCreate ? firstWord + strItemTitle : arrayNumbering.description);
          setIcon(<IconNumber className="icon__Form_title" />);
          break;
        case 'Dispositivo':
          // newUserName = usersFormReducer?.firstname + ' ' + usersFormReducer?.lastname;

          setTitle(isCreate || type === 'createUserDispo' ? 'Nuevoxx ' : formExtensionsReducer.identification);

          // type === 'createUserDispo'
          //   ? setIcon(<IconPlus className="icon__Form_title1" color="white" width={'35px'} height={'35px'} />)
          //   :
          setIcon(<IconDevices className="icon__Form_title" width={'25px'} height={'25px'} />);
          // setIcon2(<IconPhone className="icon__Form_title2" color="#4176ff" />);
          break;
        case 'Buzón':
          setTitle(isCreate ? firstWord + strItemTitle : mailboxesFormReducer.name);
          setIcon(<IconMail className="icon__Form_title" />);
          break;
        case 'Lista de difusión':
          setTitle(isCreate ? firstWord + strItemTitle : arrayEMFormListExtensions.listName !== '' ? arrayEMFormListExtensions?.listName : 'Lista de difusión ');
          setIcon(<IconSpeakerphone className="icon__Form_title" />);
          break;

        case 'Conferencia':
          setTitle(isCreate ? firstWord + strItemTitle : conferenceFormReducer.name);
          setIcon(<IconMicrophone2 className="icon__Form_title" />);
          break;
        case 'Ivr':
          setTitle(isCreate ? firstWord + strItemTitle : ivrFormReducer.name);
          setIcon(<IconPhone className="icon__Form_title" />);
          break;
        case 'Locución':
          setTitle(isCreate ? firstWord + strItemTitle : objectCreateAPPFormLocutions?.locutionName);
          setIcon(<IconMicrophone className="icon__Form_title" />);
          break;
        case 'Marcación abreviada':
          setTitle(isCreate ? firstWord + strItemTitle : shortMarkingFormReducer.nombre);
          setIcon(<IconTableShortcut className="icon__Form_title" />);
          break;
        case 'Música en espera':
          setTitle(isCreate ? firstWord + strItemTitle : songWaitingFormReducer.name);
          setIcon(<IconPlaylist className="icon__Form_title" />);
          break;
        case 'Dominio':
          setTitle(isCreate ? firstWord + strItemTitle : domainsFormReducer.domain);
          setIcon(<IconWorldWww className="icon__Form_title" />);
          break;
        case 'Lista blanca':
          setTitle(isCreate ? firstWord + strItemTitle : whitelistFormReducer.ipAddr);
          setIcon(<IconLicense className="icon__Form_title" />);
          break;
        case 'Sede':
          setTitle(isCreate ? firstWord + strItemTitle : companyFormReducer.name);
          setIcon(<IconHome className="icon__Form_title" />);
          break;
        case 'Numeración externa':
          setTitle(isCreate ? firstWord + strItemTitle : formListNumberingReducer.name);
          setIcon(<IconLicense className="icon__Form_title" />);
          break;

        case 'Grupo categoría':
          setTitle(isCreate ? firstWord + strItemTitle : categoriesGroupFormReducer.name);
          setIcon(<IconGridDots className="icon__Form_title" />);
          break;
        case 'Categoría':
          setTitle(isCreate ? firstWord + strItemTitle : formCategories.steps[0].input[0].defaultValues);
          setIcon(<IconBoxPadding className="icon__Form_title" />);
          break;
        case 'API':
          setTitle(isCreate ? firstWord + strItemTitle : 'API ');
          setIcon(<IconApiApp className="icon__Form_title" />);
          break;

        case 'Troncal':
          setTitle(isCreate ? firstWord + strItemTitle : 'Troncal ');
          setIcon(<IconArrowWaveRightUp className="icon__Form_title" />);
          break;
        case 'Calendario':
          setTitle(isCreate ? firstWord + strItemTitle : calendarFormReducer?.name !== '' ? calendarFormReducer?.name : 'Calendario');
          setIcon(<IconCalendar className="icon__Form_title" />);
          break;
        case 'Terminales':
          setTitle(isCreate ? firstWord + strItemTitle : calendarFormReducer?.name !== '' ? calendarFormReducer?.name : 'Terminales');
          setIcon(<IconTerminal className="icon__Form_title" />);
          break;
        case 'Departamento':
          setTitle(isCreate ? firstWord + strItemTitle : calendarFormReducer?.name !== '' ? calendarFormReducer?.name : 'Departamento');
          setIcon(<IconSchema className="icon__Form_title" />);
          break;
        case 'Marca':
          setTitle(isCreate ? firstWord + strItemTitle : formMarcas.marca);
          setIcon(<IconBrandMedium className="icon__Form_title" />);
          break;
        case 'Modelo':
          setTitle(isCreate ? firstWord + strItemTitle : formModelo?.modelo !== '' ? formModelo?.modelo : 'Modelo');
          setIcon(<IconCoinMonero className="icon__Form_title" />);
          break;
        case 'Plantilla':
          setTitle(isCreate ? firstWord + strItemTitle : formPlantilla?.nombre !== '' ? formPlantilla?.nombre : 'Plantilla');
          setIcon(<IconListNumbers className="icon__Form_title" />);
          break;

        default:
          setIcon(<IconLicense className="icon__Form_title" />);
          break;
      }
    }
  };

  useEffect(() => {
    ((type === 'createRule' || (type === 'modalCreateUserDispo' && title === 'Dispositivo') || (type === 'modalEditUserDispo' && title === 'Dispositivo')) &&
      title !== 'Crear nueva regla' &&
      title !== 'Nuevo') ||
    (type === 'editRule' && title !== 'Editar regla') ||
    (type === 'edit' && (title === '' || title === 'Usuario')) ||
    title === 'Numeración externa' ||
    title === 'Buzón' ||
    title === 'Usuario' ||
    title === 'Nuevo Usuario' ||
    title === 'Grupo categoría' ||
    title === 'Categoría' ||
    title === 'Sede' ||
    title === 'Lista blanca' ||
    title === 'Calendario' ||
    title === 'Dispositivo' ||
    title === 'Conferencia' ||
    title === 'Cola' ||
    title === 'Ivr' ||
    title === 'Troncal' ||
    title === 'Nuevo Dispositivo' ||
    title === 'Plantilla' ||
    title === 'Modelo' ||
    title === 'Alerta' ||
    title === 'Locución' ||
    title === 'Marcación abreviada' ||
    title === 'Música en espera' ||
    title === 'API' ||
    title === 'Dominio' ||
    title === 'Terminal' ||
    title === 'Departamento' ||
    title === 'Lista de difusión' ||
    title === 'Marca'
      ? setFormTitle()
      : null;
  }),
    [title];

  const newTitle = title[0] && title.includes(' ') ? title[0] + title.split(' ')[1][0] : title;
  return (
    <div className={'form__title' + (type === 'modalEditUserDispo' || type === 'editRule' || type === 'modalCreateUserDispo' ? '__modal' : '')}>
      {Icon}
      {formTitle[0] !== 'Usuario' ? title : <div className="form__user__logo">{newTitle.length > 2 && formTitle[0] === 'Usuario' ? newTitle[0] + newTitle[1] : newTitle}</div>}
      {/* {title.includes(' ') && type === )'edit' ? <div className="form__user__logo">{title[0] + title.split(' ')[1][0]}</div> : null} */}
      {/* {type === 'edit' || type === 'create' || type === 'modalEditUserDispo' ? ( */}

      {/* ) : ( */}
      {/* <> {Icon}</> */}
      {/* )} */}
      {/* {formTitle[0] !== 'Usuario' ? Icon : null} */}
      {/* {Icon2} */}
      {/* <span className="title__Form_title">{title.length ? title : type === 'modalCreateUserDispo' ? 'Nuevo dispositivo' : 'Nuevo'}</span> */}
    </div>
  );
};

FormTitle.propTypes = { formTitle: array, formIcon: string, type: string };
