import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const DestinationSelectWrapper = styled.div`
  .basic-single {
    flex: 1;
    color: black;
    box-sizing: border-box;
    height: 40px;
    border: none;
    width: 100%;
    div {
      border-radius: 10px;
    }
  }

  .basic {
    flex: 1;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    height: 35px;
    width: 50%;
    border: none;
    margin-left: 10px;
  }

  .basic__destination {
    color: black;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    top: -20px;
    left: 260px;
    border: none;
  }

  .destination-select {
    width: auto;
    margin: auto;
    border-radius: 15px;
  }

  .btn-Crear {
    width: 30px;
    height: 30px;
    margin: auto;
    padding: 5px;
    color: ${colors.primaryColor};
    background-color: ${colors.greenGYGAS};
    cursor: pointer;
    border-radius: 5px;
  }
`;
