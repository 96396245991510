import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const NavButtonsWrapper = styled.div`
  width: 100%;
  width: 100%;
  ${'' /* height: 100%; */}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

  ${'' /* padding-left: 68px; */}
  ${'' /* position:fixed; */}

  .icon__action__container {
    width: max-content;
    display: flex;
    color: black;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;

    .icon_add_container {
      width: max-content;
      height: 35px;
      display: flex;
      gap: 5px;
      color: black;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: ${colors.greenGYGAS};
      border-radius: 15px;
      ${
        '' /* box-shadow: 1px 1px 4px 0px #526581;
      -webkit-border-radius: 15px 15px 15px 15px;
      -webkit-transition: all 0.5s linear; */
      }

      &:hover {
        cursor: pointer;
        background-color: ${colors.greenGYGASLight};
        ${
          '' /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
        transform: scale(1.05); */
        }
      }

      span {
        padding-right: 10px;
      }

      .icon_add {
        width: 25px;
        height: 25px;
        padding-left: 5px;
      }
    }
  }

  .dropdown__add {
    background-color: ${colors.primaryColor};
    border: 1px solid lightgrey;
    color: white;
    border-radius: 10px;
    min-width: unset;
  }

  .dropdown__add__button {
    background-color: ${colors.white};
    color: black;
  }

  .iconActionMenu__close {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: flex-start;
    color: ${colors.black};
    border-radius: 10px;
    background-color: ${colors.white};

    p {
      color: black;
    }

    &:hover {
      border-radius: 10px;
      color: ${colors.white};
      background-color: ${colors.primaryColor};
    }

    .icon-plus {
      &:hover {
        border-radius: 10px;
        color: ${colors.white};

        background-color: ${colors.primaryColorLight};
      }
    }
  }

  .icon-separator {
    border-bottom: 2px solid #373c47;
    max-height: 2px;
    padding: 0;
  }

  .icon-plus2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.white};
    min-width: 40px;
    color: white;

    &:hover {
      background-color: #373c47;
      color: ${colors.white};
    }

    gap: 10px;
  }

  .icon-plus {
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    font-size: 40px;
    font-weight: 500;
    z-index: 900;
    background-color: ${colors.primaryColor};
    color: ${colors.white};
    ${'' /* border: 2px solid white; */}

    ${
      '' /* &:hover {
      cursor: pointer;
      border: 2px solid ${colors.blue};
    } */
    }
  }

  .icon-edit {
    ${'' /* color: ${colors.white}; */}
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 40px;
    font-weight: 500;
    z-index: 900;

    &:hover {
      cursor: pointer;
      border-radius: 10px;
      ${'' /* background-color: ${colors.blue}; */}
      color: ${colors.white};
    }
  }

  .right {
    height: 100%;
    width: 50%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex: 0;

    ${'' /* background-color: ${colors.backgroundColor}; */}
    .icon-dropDown {
      height: 35px;
      width: 35px;
      padding: 5px;
      color: ${colors.primaryColor};
      border-radius: 10px;
      ${'' /* border: 1px solid ${colors.primaryColorLight}; */}
      ${'' /* background-color: ${colors.whiteLight}; */}

      &:hover {
        border: 1px solid ${colors.backgroundColor};
        ${'' /* background-color: ${colors.primaryColor}; */}
        color: ${colors.white};

        .title-dropDown {
          color: ${colors.white};
        }

        .icon-dropDown {
          color: ${colors.white};
        }
      }
    }
  }

  .custom-file-input {
    background-color: ${colors.greyButton};
    border-radius: 8px;
    border: 0px;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black};
  }

  .changeView {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 40px;
    cursor: pointer;
    color: ${colors.grey};
    // //transition: all 0.3s;

    &:hover {
      background-color: ${colors.primaryColor};
      // //transition: all 0.3s;
      color: white;
    }
    &__card,
    &__table {
      margin-right: 4px;
      font-size: ${newFontSize.button};
      font-weight: ${weight.medium};
    }

    &__line {
      margin-right: 4px;
    }

    &__isActive {
      margin-right: 4px;
      cursor: pointer;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.medium};
    }
  }
`;
