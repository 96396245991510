import styled, { keyframes } from 'styled-components';
import { colors } from '../../theme/colors.js';
import { fontFamily, newFontSize, weight } from '../../theme/fonts.js';

const blob_turn = keyframes`

    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }

`;

const blob_skew = keyframes`

    0%  { transform: skewY(0deg); }
    13% { transform: skewY( calc( (1.8deg) * 2) ); }
    18% { transform: skewY( calc( (2.2deg) * 2) ); }
    24% { transform: skewY( calc( (2.48deg) * 2) ); }
    25% { transform: skewY( calc( (2.5deg) * 2) ); }
    26% { transform: skewY( calc( (2.48deg) * 2) ); }
    32% { transform: skewY( calc( (2.2deg) * 2) ); }
    37% { transform: skewY( calc( (1.8deg) * 2) ); }
    50% { transform: skewY(0deg); }
    63% { transform: skewY( calc( (-1.8deg) * 2) ); }
    68% { transform: skewY( calc( (-2.2deg) * 2) ); }
    74% { transform: skewY( calc( (-2.48deg) * 2) ); }
    75% { transform: skewY( calc( (-2.5deg) * 2) ); }
    76% { transform: skewY( calc( (-2.48deg) * 2) ); }
    82% { transform: skewY( calc( (-2.2deg) * 2) ); }
    87% { transform: skewY( calc( (-1.8deg) * 2) ); }
    100%{ transform: skewY(0deg); }

`;

const blob_scale = keyframes`

    0%  { transform: scaleX(.9) scaleY(1); fill:  ${colors.greenGYGASLight};}
    25% { transform: scaleX(.9) scaleY(.8); fill: ${colors.greenGYGASLight}}
    50% { transform: scaleX(1) scaleY(.9); fill: ${colors.greenGYGAS}}
    75% { transform: scaleX(.9) scaleY(.8);fill: ${colors.greenGYGAS} }
    100%{ transform: scaleX(.9) scaleY(1);  fill:  ${colors.greenGYGAS};}

`;

export const BubbleContainer = styled.div`
  // opacity: 0.8;
  height: 190px;
  width: 260px;
  fill: #7f40a6;

  animation: ${blob_turn} ${(props) => props.time}s linear infinite;
  fill: ${colors.black};
  position: relative;
  transform-origin: center;

  svg {
    animation: ${blob_skew} calc(30s * 0.5) linear 0s infinite;
    transform-origin: center;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -moz-box-shadow: 12px 2px 4px 0px ${colors.greenGYGASLight};
    -webkit-box-shadow: 12px 2px 4px 0px ${colors.greenGYGASLight};
    box-shadow: 12px 4px 4px 5px ${colors.greenGYGAS};
    border-radius: 50%;
    background-color: ${colors.greenGYGASLight};
  }

  svg path {
    animation: ${blob_scale} calc(30s * 0.5) ease-in-out 0s infinite;
    transform-origin: center;
  }
`;

export const LoginWrapper = styled.div`
  box-sizing: border-box;
  background: ${colors.backgroundColor};
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.6fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .icon__showPass {
    position: relative;
    left: 370px;
    top: -40px;
    color: ${colors.primaryColor};
  }

  .bubble1 {
    grid-row: 4 / 5;
    grid-column: 1;

    justify-self: center;
    align-self: center;

    height: 240px;
    width: 300px;

    img {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  .bubble2 {
    grid-column: 3;
    grid-row: 2 / 3;

    justify-self: center;
    align-self: center;

    height: 240px;
    width: 300px;

    img {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  .form {
    height: 100%;
    max-height: 650px;
    width: 100%;
    max-width: 550px;
    background-color: ${colors.primaryColor};
    padding: 40px;
    border-radius: 15px;
    grid-column: 2;
    grid-row: 1 / 8;
    z-index: 1;
    display: grid;
    grid-template-rows: 0fr 0fr 1fr 3fr 5fr 5fr 5fr;
    ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}

    ${
      '' /* &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
    } */
    }
    &__logo {
      grid-row: 1;

      justify-self: center;
      align-self: start;

      // height: 1px;
      width: 280px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__subtitle {
      grid-row: 4;
      // transform: color blue color green;
      // transform-origin: center;
      justify-self: start;
      align-self: center;
      text-align: center;
      padding-left: 40px;

      h1 {
        font-size: ${newFontSize.button};
        font-weight: bold;
        justify-self: center;
        color: ${colors.backgroundColor};
        align-self: center;
        margin: 0;
      }

      h4 {
        font-size: ${newFontSize.h4};
        // font-weight: ${weight.medium};
        color: ${colors.backgroundColor};
        justify-self: center;
        align-self: start;
        margin: 0;
      }
    }

    &__title {
      grid-row: 2;
      justify-self: center;
      align-self: center;
      top: 200px;
      margin: auto;
      background-color: unset;
      position: unset;
      width: unset;

      h1 {
        margin: 0;
        font-size: ${newFontSize.h1};
        font-weight: ${weight.bold};
        color: ${colors.primaryColor};
        line-height: 56px;
        letter-spacing: 0.25px;
        text-align: center;
      }

      h4 {
        margin: 0;
        font-size: 12px;
        // font-weight: ${weight.bold};
        color: ${colors.backgroundColor};
        line-height: 30px;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }
    }

    &__contain {
      display: grid;

      grid-row: 5 / 8;
    }

    &__steps-contain {
      display: grid;
      grid-template-columns: 1fr;
      // grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr;
      justify-self: center;
      width: 100%;
    }

    &__contain-email {
      grid-row-start: 1;
      display: grid;
      grid-template-rows: 0.4fr 1fr;
      justify-self: center;
      width: 90%;
      max-width: 405px;
      align-self: start;
    }
    &__contain_email-label {
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        margin-right: 10px;
      }

      label {
        font-size: 16px;
        font-weight: ${weight.regular};
        color: ${colors.backgroundColor};
        line-height: 14px;
        letter-spacing: 0.25px;
        padding-left: 5px;
      }
    }

    &__contain_email-input {
      height: 100%;
      width: 100%;
      max-width: 405px;
      margin-top: 15px;
      justify-self: center;
      ${'' /* font-size: 16px; */}
      // margin-right: 30px;

      input {
        width: 100%;
        border: 1px solid ${colors.grey};
        height: 63.05px;
        font-size: 16px;
        font-weight: ${weight.regular};
        padding-left: 30px;
        border-radius: 10px;

        &::placeholder {
          color: ${colors.primaryColor};
        }

        &:focus::placeholder {
          color: transparent;
        }
      }
    }
    &__contain-password {
      grid-row-start: 3;
      display: grid;
      grid-template-rows: 0.4fr 1fr;
      width: 90%;
      max-width: 405px;
      justify-self: center;
      align-self: start;
    }
    &__contain_password-label {
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        margin-right: 10px;
        stroke: ${colors.backgroundColor};
      }

      label {
        font-size: 16px;
        font-weight: ${weight.regular};
        color: ${colors.backgroundColor};
        line-height: 14px;
        letter-spacing: 0.25px;
        padding-left: 5px;
      }
    }

    &__contain_password-input {
      height: 100%;
      width: 100%;
      max-width: 405px;
      margin-top: 15px;
      justify-self: center;
      // margin-right: 30px;

      input {
        width: 100%;
        border: 1px solid ${colors.grey};
        height: 63.05px;
        font-size: 16px;
        font-weight: ${weight.regular};
        padding-left: 30px;
        border-radius: 10px;

        &::placeholder {
          color: ${colors.black};
        }

        &:focus::placeholder {
          color: transparent;
        }
      }
    }

    &__contain-button {
      grid-row-start: 5;
      display: flex;
      align-items: start;
      width: 90%;
      max-width: 450px;
      border-radius: 50px;
      justify-self: center;

      .button {
        background-color: ${colors.primaryColor};
        height: 65px;
        width: 100%;
        ${'' /* animation: example 6s linear 3s infinite alternate; */}
        // animation-duration: 4s infinite;
        font-size: ${newFontSize.button};
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.greyLine};
        border: 0.5px solid ${colors.secondaryColor};
        border-radius: 30px;
        font-family: ${fontFamily.font};
        font-weight: ${weight.bold};
        ${'' /* box-shadow: rgb(82, 101, 129) 2px 2px 4px 0px; */}

        &:hover {
          background-color: ${colors.primaryColorLight};
          cursor: pointer;
          color: ${colors.white};
          border: 1px solid ${colors.primaryColor};
          ${
            '' /* box-shadow: rgba(0, 0, 0, 0.4) 15px 15px 20px;
          transform: scale(1.05); */
          }
        }
        ${'' /* &:hover { */}
        ${
          '' /* background-color: ${colors.primaryColorLight};
          color: ${colors.backgroundColor};
          -webkit-transition: all 0.5s linear;
          -moz-transition: all 0.5s linear;
          -o-transition: all 0.5s linear;
          transition: all 0.5s linear;
          -moz-box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight};
          -webkit-box-shadow: 2px 2px 4px 0px ${colors.primaryColorDark};
          box-shadow: 2px 2px 4px 0px ${colors.primaryColorLight}; */
        }
          ${
          '' /* -moz-border-radius: 5px 5px 5px 5px;
          -webkit-border-radius: 5px 5px 5px 5px; */
        }
        ${'' /* } */}
      }
    }
  }
  ${
    '' /* @keyframes example {
    0% {
      background-color: ${colors.primaryColorLight};
    }
    25% {
      background-color: ${colors.primaryColor};
    }
    50% {
      background-color: ${colors.primaryColor};
    }
    75% {
      background-color: ${colors.primaryColorLight};
    }
    100% {
      background-color: ${colors.primaryColorLight};
    }
  } */
  }
`;
