import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const SongWaitingWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .contain__table {
    width: 100%;
    box-sizing: border-box;
    height: calc(100% - 55px);
    display: flex;
    justify-content: space-between;
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    width: ${(p) => (p.isFilter ? '300px' : '0')};
    transition: width 0.3s ease-in-out;
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }

  .react-audio-player {
    width: 50px;
  }
`;
