import React, { useEffect } from 'react';
import { ToggleButtonWrapper } from './ToggleButton.style';
import { bool, func, string } from 'prop-types';

import { IconBrandHipchat } from '@tabler/icons-react';
import { IconCloudUpload } from '@tabler/icons-react';
// import { useStore } from 'react-redux';

export const ToggleButton = ({ form, setInputValue, isActive, setIsActive, isShowCard, textRight, textLeft, twoButtonActive = 'false', defaultValue }) => {
  // const { getState } = useStore();
  // const { objectCreateAPPFormLocutions } = getState();

  useEffect(() => {
    if (defaultValue !== undefined) {
      setIsActive(defaultValue);
    }
  }, []);

  const handleSwitch = () => {
    // if ((item.currentTarget[1] && isActive && item.currentTarget[1][0] === 'Subir archivo') || (item.currentTarget[0] && !isActive && item.currentTarget[0][1] === 'Audio desde texto')) {
    const newValue = defaultValue === false ? true : false;
    setIsActive(newValue);

    setInputValue((prevState) => ({
      ...prevState,
      type: newValue,
    }));

    const action = {
      action: 'input',
      value: newValue,
    };

    const element = {
      form: 'locution',
      element: 'type',
    };
    form(action, element);
  };
  // };

  return (
    <ToggleButtonWrapper isActive={isActive} isShowCard={isShowCard} twoButtonActive={twoButtonActive}>
      <div className={'toggleButton'}>
        <div className={'toggle__options__left' + (isActive === true ? ' active' : '')} onClick={handleSwitch}>
          <span className={isActive === true ? 'active__label' : ''}> {textLeft}</span>
          <IconBrandHipchat className={'toggleButton'} />
        </div>
        <div className={'toggle__options__right' + (isActive === false ? ' active' : '')} onClick={handleSwitch}>
          <IconCloudUpload className={'toggleButton'}></IconCloudUpload>
          <span className={isActive === false ? 'active__label' : 'label'}>{textRight}</span>
        </div>
      </div>
    </ToggleButtonWrapper>
  );
};

ToggleButton.propTypes = { form: func, setInputValue: func, isActive: bool, setIsActive: func, isShowCard: bool, textRight: string, textLeft: string, twoButtonActive: string, defaultValue: bool };
