import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSwal } from '../../hooks/useSwal';
import useNumbering from '../../hooks/useNumbering';
// import { iconButton } from '../../data/dataButtons';
import Swal from 'sweetalert2';
import { ButtonPassword } from '../ButtonPassword/ButtonPassword';

import { PasswordWatcherWrapper } from './PasswordWatcher.style';
import { bool, object, string } from 'prop-types';
import { IconClipboardCopy } from '@tabler/icons-react';

const PasswordWatcher = ({ row, showPassword, text }) => {
  const { getSipPassword } = useNumbering();
  const { typeSwal } = useSwal();

  // const { copy } = iconButton;

  const handlePassword = async () => {
    const sipPassword = await getSipPassword(row.original);

    if (sipPassword.pbxpassword) {
      typeSwal('password', sipPassword.pbxpassword || 'No tiene');
    } else {
      typeSwal('password', sipPassword.password || row.original.password || 'No tiene');
    }
  };

  const handleCopy = async () => {
    const sipPassword = await getSipPassword(row.original);

    Swal.fire({
      html: '¡Contraseña copiada al portapapeles!',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
      timer: 2000,
    });

    if (navigator.clipboard && sipPassword.pbxpassword) {
      navigator.clipboard.writeText(sipPassword.pbxpassword);
    } else {
      navigator.clipboard.writeText(sipPassword.password);
    }
  };

  return (
    <PasswordWatcherWrapper>
      {showPassword ? (
        <p>{row.original.password}</p>
      ) : (
        <>
          <ButtonPassword onClick={handlePassword} text={text} />
          <div className="passwordWatcher__contain" onClick={handleCopy} data-tooltip-delay-hide={1000}>
            {/* <img className="passwordWatcher__copy" src={copy.icon} alt={copy.alt} /> */}
            <IconClipboardCopy className="passwordWatcher__copy" />
            <div className="passwordWatcher__tooltip" id={row.original.id + '__' + text} data-tooltip-delay-hide={1000} />
          </div>
          <ReactTooltip className="tooltip" anchorSelect={'#' + row.original.id + '__' + text === 'Password' ? 'dataUserInfo' : 'dataInfo'} place="top" content={'¡Copiado!'} events={['click']} />
        </>
      )}
    </PasswordWatcherWrapper>
  );
};

PasswordWatcher.propTypes = { row: object, showPassword: bool, text: string };

export default PasswordWatcher;
