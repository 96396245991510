import styled from 'styled-components';
// import { colors } from '../../theme/colors';

export const BoxStatisticWrapper = styled.div`
  height: 100%;
  width: 100%;

  .boxStatistic_container {
    height: 120px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor};
    text-align: center;
    ${'' /* border: 2px solid ${colors.primaryColorLight}; */}
    padding: 10px;
    box-shadow: 7px 5px 13px -5px rgba(0, 0, 0, 0.68);

    h5 {
      ${'' /* font-weight: 500; */}
      font-size: 14px;
      margin: 0;
    }

    .stats {
      ${'' /* font-weight: bold; */}
      font-size: 16px;
      //padding-right: 25px;
    }
  }
`;
