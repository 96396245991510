import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';
import { weight } from '../../theme/fonts';

export const NavTableWrapper = styled.div`
  width: 100%;
    height: 35px;
    text-align: flex-end;
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0px 0;
    top: 75px;
    gap: 20px;
    justify-content: flex-start;
    padding: 0 10px 0 0px;

    span {
        padding-right: 10px;
     }

.icon_trash_container {
      width: 100px;
      height: 35px;
      display: flex;
      padding: 5px;
      gap: 5px;
      color: white;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: ${colors.primaryColorLight};
      ${'' /* box-shadow: 1px 1px 4px 0px #526581; */}
      -webkit-border-radius: 15px ;
      ${'' /* -webkit-transition: all 0.5s linear; */}
      border: 1px solid ${colors.secondaryColor};
      
      &:hover {
       cursor: pointer;
       background-color: ${colors.primaryColor};
       ${'' /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4); */}
       transform: scale(1.05);       
      }

      .icon_trash {
        width: 20px;
        height: 20px;
      }
      
      &__disabled {
        width: 100px;
        height: 35px;
        display: flex;
        padding: 5px;
        gap: 5px; 
        border: 1px solid ${colors.primaryColorLight};
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:white;
        background-color: ${colors.primaryColorLight};
        ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}
        -webkit-border-radius: 15px ;
        ${'' /* -webkit-transition: all 0.5s linear; */}
                
        .icon_trash {
          width: 20px;
          height: 20px;
        }      
      }
    }

  .navTable {
    display: inline-flex;
    height: 100%;
    gap: 10px;
    border-radius: 10px;

    &::last-child {
      border-radius: 10px;
    }

    &__selectedItems {
      width: max-content;
      margin: 0;
      font-weight: 500;
      justify-content: center;
      color: ${colors.primaryColor};
    }

    &__trash,
    &__copy,
    &__export {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 10px;
    }

    &__icon {
      color:blue;

      &:hover
      color: ${colors.black};
    }
    &__text {
      font-size: ${newFontSize.body};
      color: ${colors.black};
      font-weight: ${weight.medium};
    }

  

    &:hover {
      background-color: ${colors.blue};

      cursor: pointer;
      color: ${colors.white};

      .navTable__trash__icon,
      .navTable__copy__icon,
      .navTable__export__icon {
        color: ${colors.white};
        height: 35px;
      }

      .navTable__trash__text,
      .navTable__copy__text,
      .navTable__export__text {
        font-size: ${newFontSize.body};
        color: ${colors.white};
        font-weight: ${weight.medium};
      }
    }

    .disabled {
      height: 25px;
      width: 25px;
      opacity: 0.7;

    &:hover {
       opacity: 0.7;
         border-radius: 10px;     
      background-color:white;
      color: ${colors.black}; 
       }
    }
  }
  &__trash,
  &__copy {
  }

  .disabled {
     height: 25px;
    width: 25px;
    opacity: 0.7;   
  }



  .navTable__trash__icon {
    cursor: pointer; 
    height: 40px;
    width: 40px;
    padding: 5px;
        
   &:hover {
      border-radius: 10px;     
      background-color: ${colors.blue};
      color: ${colors.white};
    } 
  }
`;
