import home from '../../assets/images/applications/home.svg';
import locutions from '../../assets/images/applications/microphone.svg';
import music from '../../assets/images/applications/music.svg';
import phone from '../../assets/images/applications/phone.svg';
import users from '../../assets/images/applications/users.svg';
import click from '../../assets/images/notFound/click.svg';
import arrowDown from '../../assets/images/phone/arrowDown.svg';
import arrowRight from '../../assets/images/phone/arrowRight.svg';
import arrowUp from '../../assets/images/phone/arrowUp.svg';
import cross from '../../assets/images/phone/cross.svg';
import settings from '../../assets/images/setting/settings.svg';

import { IconMicrophone2, IconMail, IconMicrophone, IconPhone, IconPlaylist, IconResize, IconStack } from '@tabler/icons-react';

// Form Icons
import stethoscope from '../../assets/images/forms/stethoscope.svg';
import textInput from '../../assets/images/forms/textInput.svg';
import toggle from '../../assets/images/forms/toggle-left.svg';

import { routesApplications } from '../../navigation/routes';
import { typeRegex } from '../dataRegex';

export const optionsDestination = [
  { value: 'conference', id: 1, label: 'Conferencia', ref: 'conference', options: [] },
  {
    value: 'voicemail',
    id: 2,
    label: 'Búzon',
    ref: 'voicemail',
    options: [],
  },
  {
    id: 3,
    value: 'ivr',
    label: 'IVR',
    ref: 'ivr',
    options: [],
  },
  {
    id: 4,
    value: 'soundPrompt',
    label: 'Locución',
    options: [],
    ref: 'soundPrompt',
  },
  {
    id: 5,
    value: 'queue',
    label: 'Cola',
    options: [],
    ref: 'queue',
  },
  {
    id: 6,
    value: 'extension',
    label: 'extension',
    options: [],
    ref: 'extension',
  },
  {
    id: 7,
    value: 'emergencyManager',
    label: 'Emergency Manager',
    options: [],
    ref: 'emergencyManager',
  },
  {
    id: 8,
    value: 'external',
    label: 'Número externo',
    type: 'input',
    ref: 'external',
  },
  {
    id: 9,
    value: 'hangup',
    label: 'Colgar llamada',
    ref: 'hangup',
  },
];

export const menuApplications = [
  // {
  //   id: 1,
  //   header: 'Aplicaciones',
  //   icon: IconMail,
  //   active: true,
  //   name: 'Aplicaciones',
  //   buttons: [
  {
    id: 1,
    name: 'Buzones corporativos',
    text: 'Buzones corporativos',
    icon: IconMail,
    path: routesApplications.mailboxes,
    restricted: 'mailboxes',
    content: 'Crear buzones para tus extensiones',
  },
  {
    id: 2,
    name: 'Colas',
    text: 'Colas',
    path: routesApplications.queue,
    icon: IconStack,
    restricted: 'queue',
  },
  {
    id: 3,
    name: 'Conferencias',
    text: 'Conferencias',
    path: routesApplications.conference,
    icon: IconMicrophone2,
    restricted: 'conference',
    content: 'Crear conferencias para tus extensiones',
  },

  {
    id: 4,
    name: 'Ivr',
    text: 'Ivr',
    path: routesApplications.ivr,
    icon: IconPhone,
    restricted: 'ivr',
  },
  {
    id: 5,
    name: 'Locuciones',
    text: 'Locuciones',
    path: routesApplications.locutions,
    icon: IconMicrophone,
    restricted: 'locutions',
    content: 'Crea locuciones para tus extensiones',
  },

  {
    id: 6,
    name: 'Marcación abreviada',
    text: 'Marcación abreviada',
    path: routesApplications.shortMarking,
    icon: IconResize,
    restricted: 'shortMarking',
    content: 'Crea marcaciones abreviadas para tus extensiones',
  },
  {
    id: 7,
    name: 'Música en espera',
    text: 'Música en espera',
    path: routesApplications.songWaiting,
    icon: IconPlaylist,
    restricted: 'songWaiting',
    content: 'Crea música en espera para tus extensiones',
  },
  //   ],
  // },
];

export const formCreateLocutions = {
  title: 'Configuración de una nueva locución',
  titleEdit: 'Edita la locución',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 2,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'locution', element: 'description', unique: true },
          defaultValues: '',
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya existe una locución con este nombre',
          },
        },
        {
          layoutId: 1,
          cardType: 'select',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'locution', element: 'headquarters' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggleButton',
          label: '',
          textLeft: 'Audio desde texto',
          textRight: 'Subir archivo',
          placeholder: 'Tipo',
          icon: stethoscope,
          ref: { form: 'locution', element: 'type' },
          options: [],
          defaultValues: [],
          required: {
            text: '',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'dropzone',
          data: 'locutionsFile',
          ref: { form: 'locution', element: 'file' },
          defaultValues: {},
          acceptFile: {
            'audio/mp3': ['.mp3'],
            'audio/mpeg': ['.mp3'],
            'audio/wav': ['.wav'],
          },
          required: {
            text: '',
            validation: true,
            errorMessage: '* Carge un fichero de audio',
            regex: typeRegex.inputDropzone,
          },
        },
      ],
    },
  ],
};

export const dataScreenLocutions = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna locución creada',
    subtitle: 'Empieza por configurar una nueva locución para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    home,
    locutions,
  },
};

export const dataFilterLocutions = {
  filters: [
    {
      label: 'Grupo',
      type: 'select',
      ref: 'group',
      options: [],
    },
  ],
};

export const formCreateConference = {
  title: 'Configuración de una nueva conferencia',
  titleEdit: 'Conferencia',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      title: '',
      id: 1,
      lastStep: true,
      nextStepText: 'Siguiente Paso',
      columns: 2,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'conference', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número',
          placeholder: 'Número',
          icon: textInput,
          ref: { form: 'conference', element: 'number' },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Solo números',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'conference', element: 'company' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          label: 'Activa o desactiva el registro',
          icon: toggle,
          ref: { form: 'conference', element: 'record' },
          defaultValues: false,
          required: {
            text: ' Activado',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Pin',
          placeholder: 'Pin',
          icon: textInput,
          ref: { form: 'conference', element: 'pinConf' },
          defaultValues: null,
          required: {
            text: '* Pin',
            validation: true,
            errorMessage: '* Solo números',
            regex: typeRegex.inputNumber,
          },
        },
      ],
    },
  ],
};

export const dataScreenConferencesRoom = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna conferencia creada',
    subtitle: 'Empieza por configurar una nueva conferencia para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    // users,
    // home,
  },
};

export const dataFilterConferences = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Grabación',
      type: 'select',
      ref: 'record',
      options: [],
    },
  ],
};

// export const formCreateMailboxes = {
//   title: 'Configuración de un nuevo buzón',
//   titleEdit: 'Edita el buzón',
//   textButton: 'Guardar',
//   steps: [
//     {
//       number: '1',
//       // title: 'Nombre, Número y Pin',
//       title: '',
//       id: 1,
//       nextStepText: 'Siguiente Paso',

//       input: [
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Número',
//           placeholder: 'Número',
//           icon: textInput,
//           ref: { form: 'mailboxes', element: 'number' },
//           defaultValues: null,
//           required: {
//             text: '* Número',
//             validation: true,
//             errorMessage: '* Números',
//             regex: typeRegex.inputNumber,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Nombre',
//           placeholder: 'Nombre',
//           icon: textInput,
//           ref: { form: 'mailboxes', element: 'name' },
//           defaultValues: null,
//           required: {
//             text: '* Nombre',
//             validation: true,
//             errorMessage: '* Debe tener entre 3 y 50 caracteres',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 1,
//           cardType: 'input',
//           label: 'Pin',
//           placeholder: 'Pin',
//           icon: textInput,
//           ref: { form: 'mailboxes', element: 'pin' },
//           defaultValues: null,
//           required: {
//             text: '* Pin',
//             validation: true,
//             errorMessage: '* 6 números',
//             regex: typeRegex.inputOnlyNumbers,
//           },
//         },
//       ],
//     },
//     {
//       number: '2',
//       // title: 'Sede, Email, Locución',
//       title: '',
//       id: 2,
//       nextStepText: 'Siguiente Paso',
//       lastStep: true,
//       columns: 2,
//       input: [
//         {
//           layoutId: 2,
//           cardType: 'select-option',
//           label: 'Sede:',
//           placeholder: 'Sede',
//           icon: stethoscope,
//           ref: { form: 'mailboxes', element: 'company' },
//           options: [],
//           defaultValues: [],
//           required: {
//             text: '* Sede',
//             validation: false,
//             errorMessage: '* Selecciona una opción',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 2,
//           cardType: 'input',
//           label: 'Email',
//           placeholder: 'Email',
//           icon: textInput,
//           ref: { form: 'mailboxes', element: 'email' },
//           defaultValues: null,
//           required: {
//             text: '* Email',
//             validation: true,
//             errorMessage: '* Mail incorrecto',
//             regex: typeRegex.inputText,
//           },
//         },
//         {
//           layoutId: 2,
//           cardType: 'select-option',
//           label: 'Locución',
//           placeholder: 'Locución',
//           icon: stethoscope,
//           ref: { form: 'mailboxes', element: 'soundPrompt' },
//           options: [],
//           defaultValues: {},
//           required: {
//             text: '* Locución',
//             validation: false,
//             errorMessage: '* Selecciona una opción',
//             regex: typeRegex.inputText,
//           },
//         },
//       ],
//     },
//   ],
// };

export const formCreateMailboxes = {
  title: 'Configuración de un nuevo buzón',
  titleEdit: 'Edita el buzón',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      // title: 'Nombre, Número y Pin',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',

      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número',
          placeholder: 'Número',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'number' },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Números',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Pin',
          placeholder: 'Pin',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'pin' },
          defaultValues: null,
          required: {
            text: '* Pin',
            validation: true,
            errorMessage: '* 6 números',
            regex: typeRegex.inputOnlyNumbers,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'mailboxes', element: 'company' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Email',
          placeholder: 'Email',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'email' },
          defaultValues: null,
          required: {
            text: '* Email',
            validation: true,
            errorMessage: '* Mail incorrecto',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Locución',
          placeholder: 'Locución',
          icon: stethoscope,
          ref: { form: 'mailboxes', element: 'soundPrompt' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Locución',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenMailBoxes = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay nigun buzón creado',
    subtitle: 'Empieza por configurar un nuevo buzón para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    users,
    home,
  },
};

export const dataFilterMailboxes = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateSongWaiting = {
  title: 'Configuración de una nueva música de espera',
  titleEdit: 'Edita la música de espera',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      // title: 'Nombre y Sede',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'songWaiting', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'songWaiting', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },

        {
          cardType: 'multiselect',
          label: 'Locuciones',
          icon: textInput,
          ref: { form: 'songWaiting', element: 'soundPrompt' },
          defaultValues: null,
          // options: [],
          required: {
            text: '* Locuciones',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        //     {
        //       layoutId: 2,
        //       cardType: 'multiselect',
        //       label: 'Locuciones',
        //       icon: toggle,
        //       ref: { form: 'songWaiting', element: 'soundPrompt' },
        //       defaultValues: [],
        //       options: [],
        //       required: {
        //         text: '* Locuciones',
        //         validation: true,
        //         errorMessage: '* Selecciona una opción',
        //         regex: typeRegex.inputText,
        //       },
        //     },
      ],
    },
  ],
};

export const dataFilterSongWaiting = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Activo',
      type: 'select',
      ref: 'active',
      options: [],
    },
  ],
};

export const dataScreenSongWaiting = {
  title: [
    {
      text: 'Musica de espera',
      icon: music,
    },
    {
      text: 'Locuciones',
      icon: locutions,
    },
  ],
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna música en espera creada',
    subtitle: 'Empieza por configurar una nueva música en espera para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    home,
    locutions,
  },
};

export const formCreateShortMarking = {
  title: 'Configuración de una nueva marcación abreviada',
  titleEdit: 'Edita la marcación abreviada',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      // title: 'Nombre, Número, Destino y Sede',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'nombre' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número abrev',
          placeholder: 'Número abrev',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'num_corto' },
          defaultValues: null,
          required: {
            text: '* Número abrev',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Destino',
          placeholder: 'Destino',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'destino' },
          defaultValues: null,
          required: {
            text: '* Destino',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Sede',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'shortMarking', element: 'company' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenShortMarking = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay niguna marcación abreviada creada',
    subtitle: 'Empieza por configurar un nueva marcación abreviada para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    users,
    home,
  },
};

export const dataFilterShortMarking = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateIvr = {
  title: 'Configuración de una nueva Ivr',
  titleEdit: 'Edita el Ivr',
  textButton: 'Guardar',
  steps: [
    {
      number: '1',
      // title: 'Nombre y Locución, Sede',
      title: '',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'ivr', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          cardType: 'select-option',
          label: 'Locución:',
          placeholder: 'Locución',
          icon: stethoscope,
          ref: { form: 'ivr', element: 'soundPrompt' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Locución',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'ivr', element: 'company' },
          options: [],
          defaultValues: [],
          required: {
            text: '* Sede',
            validation: false,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },

        {
          cardType: 'phone',

          label: 'Selecciona un botón del teclado númerico y asigna un destino en el desplegable.',
          icon: phone,
          ref: { form: 'ivr', element: 'destination' },
          defaultValues: null,

          required: {
            text: 'Selecciona un botón del teclado númerico y asigna un destino en el desplegable.',
            validation: false,
            errorMessage: '* Crea un destino',
            regex: typeRegex.inputText,
            // regex: /^/,
          },
        },
        // {
        //   cardType: 'input',

        //   label: 'Selecciona el timeout2. ',
        //   icon: phone,
        //   ref: { form: 'ivr', element: 'timeout' },
        //   defaultValues: 2,

        //   required: {
        //     text: 'Selecciona el timeout2.',
        //     validation: true,
        //     errorMessage: '* Indica un timeout (Número del 0 al 10.',
        //     regex: /^/,
        //   },
        // },
      ],
    },
  ],
};

export const dataFilterIvr = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Activo',
      type: 'select',
      ref: 'active',
      options: [],
    },
  ],
};

export const dataScreenIvr = {
  title: [
    {
      text: 'Ivr',
      icon: music,
    },
    {
      text: 'Ivr',
      icon: locutions,
    },
  ],
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna Ivr creada',
    subtitle: 'Empieza por configurar una nueva Ivr en espera para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {},

  phone: {
    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
    text: 'Tecla',
    timeOut: 'Time Out',
    notPressed: 'Si no se pulsa',
    icon: {
      arrowDown: {
        img: arrowDown,
        alt: 'arrowDown',
      },
      arrowRight: {
        img: arrowRight,
        alt: 'arrowRight',
      },
      arrowUp: {
        img: arrowUp,
        alt: 'arrowUp',
      },
      cross: {
        img: cross,
        alt: 'cross',
      },
    },
  },
};

export const dataScreenQueue = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna cola creada',
    subtitle: 'Empieza por configurar una nueva cola para visualizar en tu escritorio',
    textButton: 'Guardar',
  },
  icon: {
    // users,
    // home,
  },
};

export const dataFilterQueue = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateQueue = {
  title: 'Nueva cola',
  titleEdit: 'Editar la cola',
  tabs: [
    {
      // textButton: 'Crear cola',
      id: 1,
      number: '1',
      text: 'Colas y agentes',
      steps: [
        {
          number: '1',
          title: 'Cola',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'input',
              label: 'Nombre',
              placeholder: 'Nombre',
              icon: textInput,
              ref: { form: 'queue', element: 'name' },
              defaultValues: null,
              required: {
                text: '* Nombre',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'input',
              label: 'Número',
              placeholder: 'Número',
              icon: textInput,
              ref: { form: 'queue', element: 'number' },
              defaultValues: null,
              required: {
                text: '* Número',
                validation: true,
                errorMessage: '* Mínimo 3 números',
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Número máx. de personas en espera',
              placeholder: 'Número máx. de personas en espera',
              icon: textInput,
              ref: { form: 'queue', element: 'max_waiting_users' },
              defaultValues: null,
              required: {
                text: '* Número máx. de personas en espera',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo de ring por extensión',
              placeholder: 'Tiempo de ring por extensión',
              icon: textInput,
              ref: { form: 'queue', element: 'call_to_use_extensions' },
              defaultValues: null,
              required: {
                text: '* Tiempo de ring por extensión',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo max. de estancia en cola',
              placeholder: 'Tiempo max. de estancia en cola',
              icon: textInput,
              ref: { form: 'queue', element: 'timeout_queue' },
              // defaultValues: null.title__Form_tit,
              required: {
                text: '* Tiempo max. de estancia en cola',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Reintentos',
              placeholder: 'Reintentos',
              icon: textInput,
              ref: { form: 'queue', element: 'retry' },
              defaultValues: null,
              required: {
                text: '* Reintentos',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            {
              cardType: 'input',
              label: 'Prioridad de cola',
              placeholder: 'Prioridad de cola',
              icon: stethoscope,
              ref: { form: 'queue', element: 'timeout_priority' },
              options: [],
              defaultValues: {},
              required: {
                text: '* Prioridad de cola',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            {
              cardType: 'select-option',
              label: 'Sede',
              placeholder: 'Sede',
              icon: stethoscope,
              ref: { form: 'queue', element: 'companyId' },
              options: [],
              defaultValues: [],
              required: {
                text: '* Sede',
                validation: true,
                errorMessage: '* Selecciona una opción',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'select-option',
              label: 'Música en espera',
              placeholder: 'Música en espera',
              icon: stethoscope,
              ref: { form: 'queue', element: 'musicclass' },
              options: [],
              defaultValues: [],
              required: {
                text: 'Música en espera',
                validation: false,
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'destinations',
              label: 'Destino si no hay respuesta',
              placeholder: 'Destino si no hay respuesta',
              icon: stethoscope,
              ref: { form: 'queue', element: 'destination' },
              options: [],
              defaultValues: {},
              required: {
                text: 'Destino si no hay respuesta',
                validation: false,
                regex: typeRegex.inputText,
              },
            },

            {
              cardType: 'radio',
              label: 'Selección múltiple según preferencias',
              placeholder: 'Selección múltiple según preferencias',
              icon: stethoscope,
              ref: { form: 'queue', element: 'preferences' },
              defaultValues: '',
              radioType: 'multi',
              options: [
                { label: 'Grabar', value: 'grabar', id: 1 },
                { label: 'Llamar a extensiones en uso', value: 'llamar', id: 2 },
                { label: 'Desborde automático si no hay agentes disponibles', value: 'desborde', id: 3 },
                {
                  label: 'Realiza encuesta',
                  value: 'encuesta',
                  id: 4,
                  selectData: {
                    label: 'Locución encuesta',
                    placeholder: 'Locución encuesta',
                    ref: { form: 'queue', element: 'encuesta' },
                    options: [],
                    defaultValues: {},
                  },
                },
                { label: 'Expulsión automática de la cola en uso en caso de no disponer de agentes', value: 'expulsion', id: 5 },
              ],
              required: {
                text: 'Selección múltiple según preferencias',
                validation: false,
                errorMessage: 'Selecciona una opción',
                regex: typeRegex.inputText,
              },
            },

            {
              cardType: 'cardSlider',
              label: 'Selecciona a los agentes y destinales una estrategia',
              navSlider: {
                buttonAdd: {
                  text: 'Añadir agente',
                  options: [],
                },
                selectStrategy: {
                  ref: { form: 'queue', element: 'selectStrategy' },
                  placeholder: 'Estrategia',
                  label: '* Estrategia',
                  options: [
                    {
                      label: 'Todos a la vez',
                      value: 1,
                    },
                    {
                      label: 'Por orden',
                      value: 2,
                    },
                    {
                      label: 'Por orden grupal',
                      value: 3,
                    },
                    // {
                    //     label: 'Por orden circular',
                    //     value: 'por orden circular',
                    // },
                    // {
                    //     label: 'Más tiempo en espera',
                    //     value: 'mas tiempo en espera',
                    // },
                    // {
                    //     label: 'Menos llamadas atendidas',
                    //     value: 'menos llamadas atendidas',
                    // },
                    // {
                    //     label: 'Aleatorio',
                    //     value: 'aleatorio',
                    // },
                  ],
                  defaultValues: {
                    label: 'Todos a la vez',
                    value: 1,
                  },
                },
              },
            },
          ],
        },
      ],
    },
    // {
    //     id: 2,
    //     text: 'Avisos',
    //     steps: [
    //         {
    //             number: '1',
    //             title: 'Aviso Agente',
    //             id: 1,
    //             nextStepText: 'Siguiente Paso',
    //             input: [
    //                 {
    //                     cardType: 'input',
    //                     label: 'Tiempo de descanso',
    //                     placeholder: 'Tiempo de descanso',
    //                     icon: textInput,
    //                     ref: { form: 'queue', element: 'time' },
    //                     defaultValues: null,
    //                     required: {
    //                         text: '* Tiempo de descanso',
    //                         validation: true,
    //                         errorMessage: '* Debe tener entre 3 y 50 caracteres',
    //                         regex: /^/,
    //                     },
    //                 },
    //             ],
    //         },
    //         {},
    //     ],
    // },
  ],
};

export const formCreateQueue_Wide4 = {
  title: 'Nueva cola',
  titleEdit: 'Editar',
  tabs: [
    {
      id: 1,
      text: 'Configuraciones básicas',
      textButton: 'Guardar',
      icon: settings,
      steps: [
        {
          number: '1',
          title: '',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'input',
              label: 'Nombre',
              placeholder: 'Nombre',
              icon: textInput,
              ref: { form: 'queue', element: 'name' },
              defaultValues: null,
              required: {
                text: '* Nombre',
                validation: true,
                errorMessage: '* Nombre sin espacios en blanco',
                regex: typeRegex.textNoSpaces,
              },
            },
            {
              cardType: 'select-option',
              label: 'Extensión',
              placeholder: 'Extensión',
              icon: textInput,
              ref: { form: 'queue', element: 'extensionId' },
              defaultValues: null,
              required: {
                text: '* Extensión',
                validation: false,
                errorMessage: '* Mínimo 3 números',
                regex: typeRegex.multiselect,
              },
            },
            {
              cardType: 'select-option',
              icon: textInput,
              label: '* Estrategia',
              placeholder: 'Estrategia',
              ref: { form: 'queue', element: 'strategyId' },
              options: [
                {
                  label: 'Ring All',
                  value: 1,
                },
                {
                  label: 'Lineal',
                  value: 2,
                },
                {
                  label: 'Least Recent',
                  value: 3,
                },
                {
                  label: 'Fewest Calls',
                  value: 3,
                },
                {
                  label: 'Random',
                  value: 3,
                },
                {
                  label: 'round Robin',
                  value: 3,
                },
              ],
              defaultValues: {
                label: 'Ring All',
                value: 1,
              },
              required: {
                text: 'Estrategia',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            // {
            //   cardType: 'select-option',
            //   label: 'Música en espera',
            //   placeholder: 'Música en espera',
            //   icon: stethoscope,
            //   ref: { form: 'queue', element: 'musicclass' },
            //   options: [],
            //   defaultValues: [],
            //   required: {
            //     text: 'Música en espera',
            //     validation: false,
            //     regex: typeRegex.inputText,
            //   },
            // },
            {
              cardType: 'select-option',
              label: 'Música en espera',
              placeholder: 'Música en espera',
              icon: textInput,
              ref: { form: 'queue', element: 'mohId' },
              defaultValues: null,
              required: {
                text: 'Música en espera',
                validation: false,
                errorMessage: '* Mínimo 3 letras',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'input',
              label: 'Tamaño Max. cola',
              placeholder: '5',
              icon: textInput,
              ref: { form: 'queue', element: 'queueMaxWaitTime' },
              defaultValues: null,
              required: {
                text: '* Tamaño Max. cola',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo para colgar',
              placeholder: '5',
              icon: textInput,
              ref: { form: 'queue', element: 'timeHangup' },
              defaultValues: null,
              required: {
                text: '* Tiempo para colgar',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo reintento',
              placeholder: '10',
              icon: textInput,
              ref: { form: 'queue', element: 'time_retry' },
              defaultValues: null,
              required: {
                text: '* Tiempo reintento',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo ring',
              placeholder: '5',
              icon: textInput,
              ref: { form: 'queue', element: 'time_ring' },
              defaultValues: null,
              required: {
                text: '* Tiempo ring',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'toggle',
              label: 'Auto grabar',
              placeholder: 'Auto grabar',
              icon: textInput,
              ref: { form: 'queue', element: 'isRecord' },
              defaultValues: false,
              required: {
                text: 'Grabar',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'toggle',
              label: 'Activar',
              placeholder: 'Activar',
              icon: textInput,
              ref: { form: 'queue', element: 'isCustomWelcome' },
              defaultValues: null,
              required: {
                text: 'Activar',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'select-option',
              label: 'Personalizar',
              placeholder: 'Mensaje bienvenida',
              icon: textInput,
              ref: { form: 'queue', element: 'customWelcomeId' },
              defaultValues: null,
              required: {
                text: 'Mensaje de bienvenida',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'toggle',
              label: 'Activar',
              placeholder: 'Activar',
              icon: textInput,
              ref: { form: 'queue', element: 'isSurvey' },
              defaultValues: null,
              required: {
                text: 'Activar',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'select-option',
              label: 'Personalizar',
              placeholder: 'Instrucciones para encuestas',
              icon: textInput,
              ref: { form: 'queue', element: 'survey_soundpromptId' },
              defaultValues: null,
              required: {
                text: 'Instrucciones para encuestas',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'input',
              label: 'Tiempo máx. espera',
              placeholder: 'Tiempo max. espera',
              icon: textInput,
              ref: { form: 'queue', element: 'maxWaitTime' },
              defaultValues: 60,
              required: {
                text: '* Tiempo máx. espera',
                validation: true,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'destinations',
              label: 'Destino',
              placeholder: 'Destino',
              icon: textInput,
              ref: { form: 'queue', element: 'destinationTypeId' },
              defaultValues: null,
              required: {
                text: 'Destino',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'toggle',
              label: 'Activar',
              placeholder: 'Activar',
              icon: textInput,
              ref: { form: 'queue', element: 'isCustomPrompt' },
              defaultValues: null,
              required: {
                text: 'Activar',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'select-option',
              label: 'Mensaje',
              placeholder: 'Mensaje',
              icon: textInput,
              ref: { form: 'queue', element: 'customPromptId' },
              defaultValues: null,
              required: {
                text: 'Mensaje',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'destinations',
              label: 'Destino',
              placeholder: 'Destino',
              icon: textInput,
              ref: { form: 'queue', element: 'promptDestinationTypeId' },
              defaultValues: null,
              required: {
                text: 'Destino',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
          ],
        },
      ],
    },
    {
      id: 2,
      text: 'Configuraciones avanzadas',
      textButton: 'Guardar',
      steps: [
        {
          number: '1',
          title: 'Anuncio emisor',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'toggle',
              label: 'Activado',
              placeholder: 'Activado',
              icon: textInput,
              ref: { form: 'queue', element: 'isCallerAnnouce' },
              defaultValues: false,
              required: {
                text: 'Activar posición',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'input',
              label: 'Intervalo',
              placeholder: 'Intervalo',
              icon: textInput,
              ref: { form: 'queue', element: 'announceInterval' },
              defaultValues: 5,
              required: {
                text: '* Intervalo',
                validation: true,
                errorMessage: '* Mínimo 2 números',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'input',
              label: 'Activada',
              placeholder: 'Activada',
              icon: textInput,
              ref: { form: 'queue', element: 'annouceWaitTime' },
              defaultValues: 5,
              required: {
                text: 'Tiempo de espera',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.number1digit,
              },
            },
          ],
        },
        {
          number: '2',
          title: 'Cola vacía',
          id: 2,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'select-option',
              label: 'Activada',
              placeholder: 'Activada',
              icon: textInput,
              ref: { form: 'queue', element: 'leaveWhenEmpty' },
              options: [
                {
                  label: 'Sí',
                  value: 1,
                },
                {
                  label: 'No',
                  value: 2,
                },
                {
                  label: 'Estricto',
                  value: 3,
                },
              ],
              defaultValues: {
                label: 'Estricto',
                value: 3,
              },
              required: {
                text: 'Salir si no hay agentes disponibles',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
            },

            {
              cardType: 'destinations',
              label: 'Destino Fallover',
              placeholder: 'Destino Fallover',
              icon: textInput,
              ref: { form: 'queue', element: 'falloverDestinationTypeId' },
              options: [],
              defaultValues: null,
              required: {
                text: 'Destino fallover',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
            },
            // {
            //   cardType: 'select-option',
            //   label: 'Destino',
            //   placeholder: 'Destino fallover',
            //   icon: textInput,
            //   ref: { form: 'queue', element: 'falloverDestinationId' },
            //   options: [],
            //   defaultValues: null,
            //   required: {
            //     text: 'Destino',
            //     validation: false,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     regex: typeRegex.inputText,
            //   },
            // },
            {
              cardType: 'select-option',
              label: 'No entrar sin agentes',
              placeholder: 'No entrar sin agentes',
              icon: textInput,
              ref: { form: 'queue', element: 'dialEmpty' },
              defaultValues: {
                label: 'Estricto',
                value: 3,
              },
              options: [
                {
                  label: 'Sí',
                  value: 1,
                },
                {
                  label: 'No',
                  value: 2,
                },
                {
                  label: 'Estricto',
                  value: 3,
                },
              ],
              required: {
                text: 'No entrar sin agentes',
                validation: false,
                errorMessage: '* Mínimo 1 número',
                regex: typeRegex.number1digit,
              },
            },
          ],
        },

        {
          number: '3',
          title: 'Otros',
          id: 3,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'toggle',
              label: 'Activada',
              placeholder: 'Activada',
              icon: textInput,
              ref: { form: 'queue', element: 'isHoldLog' },
              defaultValues: null,
              required: {
                text: 'Aviso de tiempo de espera',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.number1digit,
              },
            },
            {
              cardType: 'toggle',
              label: 'Activada',
              placeholder: 'Activada',
              icon: textInput,
              ref: { form: 'queue', element: 'isDisplayNameEdited' },
              defaultValues: false,
              required: {
                text: 'Cambiar caller ID',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.number1digit,
              },
            },

            {
              cardType: 'input',
              label: 'Caller ID',
              placeholder: 'Caller ID',
              icon: textInput,
              ref: { form: 'queue', element: 'displayNameEdited' },
              defaultValues: null,
              required: {
                text: 'Caller ID',
                validation: true,
                errorMessage: '* Mínimo 3 letras',
                regex: typeRegex.inputText,
              },
            },
          ],
        },
      ],
    },
    {
      id: 3,
      text: 'Agentes',
      textButton: 'Guardar',
      icon: settings,
      steps: [
        {
          number: '1',
          // title: 'General',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'toggle',
              label: 'Activada',
              placeholder: 'Activada',
              icon: textInput,
              ref: { form: 'queue', element: 'isAgentLog' },
              defaultValues: null,
              required: {
                text: 'Registro de eventos',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputNumberOneDigit,
              },
            },
            // {
            //   cardType: 'multiselect',
            //   label: 'Extensión',
            //   placeholder: 'Extensión',
            //   icon: textInput,
            //   ref: { form: 'queue', element: 'extensionId' },
            //   defaultValues: null,
            //   required: {
            //     text: 'Extensión',
            //     validation: true,
            //     errorMessage: '* Debe tener entre 3 y 50 caracteres',
            //     regex: typeRegex.inputText,
            //   },
            // },
            {
              cardType: 'multiselect',
              label: ' Agentes',
              placeholder: 'Extensiones',
              icon: textInput,
              ref: { form: 'queue', element: 'extensions' },
              defaultValues: null,
              required: {
                text: 'Agentes',
                validation: false,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
            },
          ],
        },
      ],
    },
  ],
};
