import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const PhoneWrapper = styled.div`
  height: 100%;
  width: 100%;

  .phone {
    height: 100%;
    width: 100%;
    ${'' /* padding-top: 20px; */}
    gap: 10px;

    &__info__led {
      color: white;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 160px;
      margin: auto;
      width: 100%;
      max-width: 200px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      ${'' /* max-width: 200px; */}
    }

    &__container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: left;
      column-gap: 45px;
      padding: 0px;
      align-items: flex-start;
    }

    &__key {
      height: 50px;
      width: 50px;
      margin: auto;
      color: ${colors.primaryColorLight};
    }

    &__keys {
      width: 100%;
      max-width: 250px;
      display: grid;
      margin: auto;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      column-gap: 5px;
      row-gap: 5px;
      border: 1px solid ${colors.primaryColor};
      height: 100%;
      border-radius: 10px;
      padding: 20px;
      color: ${colors.primaryColorLight};
      background-color: ${colors.primaryColorLight};
    }

    &__selection {
      height: 100%;
      width: 100%;
    }

    &__selected__item {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 8fr;
      gap: 15px;
      justify-content: center;
      align-items: center;
      padding: 5px;
      font-weight: normal;

      .phone__option__active {
        ${'' /* width: 270px; */}
        max-width: 300px;

        .destination-select {
          width: unset;
          ${'' /* max-width: 200px; */}
          font-size: 14px;
          border-radius: 10px;
        }
      }
      .phone__key__actives {
        width: 35px;
        height: 35px;
        font-weight: bold;
        font-size: 24px;
        background-color: ${colors.yellow};
        color: ${colors.primaryColor};
        border: 1px solid ${colors.primaryColor};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        ${'' /* padding: 10px; */}
      }
    }

    &__selection__items {
      ${
        '' /* position: relative;
      top: 30px; */
      }
      color: ${colors.backgroundColor};
      height: 50px;
      width: 100%;
      display: flex;
      column-gap: 30px;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      input {
        color: ${colors.primaryColor};
        font-weight: normal;
      }
    }

    &__key__active {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 18px;
      color: ${colors.primaryColor};
      text-align: left;
      flex: 1;
      border-radius: 50%;
      background-color: ${colors.greenGYGAS};
      border: 1px solid ${colors.primaryColor};
    }

    &__select {
      flex: 4;
      height: 100%;

      .destination-select {
        max-width: 350px;
        width: 100%;
        border-radius: 10px;
      }
    }

    &__list__actives {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: ${colors.backgroundColor};
      font-size: 18px;
      font-weight: bold;
      max-width: 450px;
      background: ${colors.primaryColorLight};
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid ${colors.primaryColor};
      border-radius: 10px;

      .phone__key__active {
        width: 35px;
        height: 35px;
      }

      span {
        text-align: center;
        font-size: 16px;
      }

      .phone__list__title {
        height: 20px;
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: auto;
        color: ${colors.backgroundColor};
        font-size: 18px;
        font-weight: bold;
        max-width: 450px;
        border-radius: 10px;
      }
    }

    &__list__title {
      height: 40px;
      width: max-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: ${colors.primaryColor};
      font-size: 24px;
      font-weight: bold;
      max-width: 450px;
      border-radius: 10px;
    }
  }

  .phone__info {
    display: grid;
    grid-template-columns: 1fr 8fr;
    width: 100%;
    -webkit-column-gap: 20px;
    column-gap: 20px;
    border-radius: 10px;
    font-size: 12px;
    padding: 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    margin: auto;

    .phone__option__active {
      width: 100%;
      max-width: 300px;
      margin: auto;

      .phone__option__item {
        background: ${colors.backgroundColor};
        border-radius: 10px;

        align-self: center;
        display: grid;
        padding: 8px 15px;
        grid-template-columns: 9fr 1fr;

        p {
          margin: 0px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: ${colors.black};
          align-self: center;
        }

        img {
          justify-self: end;
          align-self: center;
          color: ${colors.black};
          width: 15px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .phone__tasks__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .phone__notPressed {
    ${'' /* height: 70px; */}
    width: 100%;
    ${'' /* margin-top: 30px; */}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.primaryColor};
    background: ${colors.primaryColorLight};
    border-radius: 10px;
    padding: 10px;
    ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}
    ${'' /* -webkit-border-radius: 15px 15px 15px 15px; */}
    -webkit-transition: all 0.5s linear;

    ${
      '' /* &:hover {
      box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    } */
    }

    &__contain {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      column-gap: 20px;
    }

    &__select {
      width: 200px;
    }

    &__title {
      &__item {
        line-height: 18px;
        color: ${colors.white};
        font-weight: bold;
        font-size: 16px;
        align-self: center;
        width: max-content;
      }
    }
  }

  .phone__timeOut {
    height: 100%;
    width: max-content;
    ${'' /* margin-left: 30px; */}

    div {
      border-radius: 5px;
    }
    .inputNumber {
      &__display {
        width: 70px;
        height: 100%;
        padding: 5px;
        border-radius: 5px;
      }
    }

    &__contain {
      height: 100%;
      width: 100%;
    }
    &__title {
      p {
        margin: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${colors.white};
        align-self: center;
      }
    }
    &__input {
      ${'' /* margin-top: 10px; */}
      height: 100%;

      p {
        margin: 0px;
        font-size: 14px;
      }
    }
  }
`;
