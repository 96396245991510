import styled from 'styled-components';
import { colors } from '../../theme/colors.js';

export const StaticScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.primaryColor};
  ${'' /* transition: all 1.5s linear; */}

  .form-contain {
    box-sizing: border-box;
    width: -webkit-fill-available;
    height: 100%;
    position: fixed;
    left: ${(p) => (!p.isMenuOpen ? '69px' : '228px')};
    ${'' /* transition: width 1.5s linear; */}
    top: 50px;
    border-radius: 15px;
    border: 1px solid ${colors.primaryColor};
    background-color: ${colors.backgroundColor};
    ${'' /* transition: all 1s linear; */}

    .users-container {
      margin-bottom: 40px;
    }
    ${'' /* z-index: 10; */}

    ${
      '' /* .contain__button-save {
      opacity: 1;
      height: 35px;
      position: fixed;
      box-sizing: border-box;
      display: flex;
      justify-content: end;
      top: 70px;
      right: 20px;
      z-index: 9;

      .cancel_button {
        background-color: ${colors.primaryColorLight};
        color: white;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;
        box-shadow: 2px 2px 4px 0px #526581;
        -webkit-border-radius: 15px 15px 15px 15px;
        -webkit-transition: all 0.5s linear;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
          box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
          transform: scale(1.05);
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        border-radius: 10px;
        box-shadow: 2px 2px 4px 0px #526581;
        -webkit-border-radius: 15px 15px 15px 15px;
        -webkit-transition: all 0.5s linear;
        &:hover {
          background-color: ${colors.greenGYGASLight};
          box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
          transform: scale(1.05);
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    } */
    }
  }

  .static__screen {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: ${colors.primaryColor};
  }
  .static__container {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    ${'' /* background-color: ${colors.backgroundColor}; */}
    border-radius: 10px;
  }
  .toaster__modal {
    position: absolute;
    text-align: center;
    top: 40px;
    left: 50%;
    margin: auto;
  }

  .body__container {
    box-sizing: border-box;
    width: -webkit-fill-available;
    height: 100%;
    position: fixed;
    top: 50px;
    left: ${(p) => (p.isMenuOpen ? '229px' : '68px')};
    transition: width 1.5s linear;
    border-radius: 10px;

    .contain__table {
      width: 100%;
      margin: auto;
    }
  }
`;
