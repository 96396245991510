import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const CheckboxWrapper = styled.div`
  height: 100%;
  ${'' /* max-width: 40px; */}
  display: flex;
  justify-content: start;
  align-items: center;

  .checkbox__icon {
    position: relative;
    width: 100%;

    &__square,
    &__check {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      color: ${colors.backgroundColor};
      border: 1px solid ${colors.primaryColorLight};
      border-radius: 4px;
      // background-color: #e0e1dd;;;;;;
    }
    .checkbox__icon__check {
      color: ${colors.primaryColor};
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
