import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .form__body__modal {
    width: 100%;
    .container__cards {
      border-radius: 0px 0px 10px 10px;
      .form__title__modal {
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        gap: 10px;
        font-weight: bold;
        font-size: 24px;
        stroke: ${colors.white};
        color: ${colors.primaryColor};
        ${'' /* background-color: ${colors.primaryColorLight}; */}
        height: 50px;
        margin-top: -50px;
        ${'' /* border-radius: 10px 10px 0 0; */}

        .icon__Form_title {
          width: 35px;
          height: 35px;
          padding: 5px;
          color: rgb(51, 65, 85);
          background-color: rgb(148, 206, 13);
          border-radius: 50%;
          border: 2px solid rgb(51, 65, 85);
          ${'' /* margin-top: -25px; */}
        }

        .form__user__logo {
          padding: 10px;
        }

        .title__Form_title {
          display: flex;
          align-items: flex-end;
          color: ${colors.white};
          font-size: 16px;
          border-radius: 15px;
          justify-content: center;
          height: 100%;
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${'' /* background-color: ${colors.primaryColorLight}; */}
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }

  .pageScreen__title {
    position: fixed;
    background-color: white;
    left: 13%;
    box-sizing: border-box;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      background-color: white;
      margin-left: 10px;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.bold};
      letter-spacing: 0.25px;
    }
  }

  .icon__dispositivo {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 5px;
    background-color: ${colors.greenGYGAS};
    color: ${colors.primaryColor};
  }

  .modal__form__container {
    width: 100%;
    height: 100%;
    position: fixed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: ${colors.primaryColorLight};
    left: 0;
    top: 0;
    z-index: 9;
    opacity: 0.9;

    .modal__form__body {
      height: auto;
      width: 80%;
      max-width: 650px;
      position: absolute;
      top: 200px;
      display: flex;
      justify-content: start;
      align-items: center;
      opacity: 1;
      background-color: ${colors.backgroundColor};
      margin: auto;
      border-radius: 10px;

      .form__container {
        width: 100%;
      }

      .stepForm__contain-typeInput {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        padding: 40px;
      }
      .container__cards {
        width: 100%;

        .contain__inputCard-component {
          width: 80%;
          margin: auto;
        }

        .form__title {
          padding-top: unset;
          color: ${colors.white};
          font-weight: bold;

          .title__Form_title {
            width: 100%;
            justify-content: start;
            color: ${colors.white};
          }
        }
      }
    }

    #modalListNumber {
      background-color: green;
    }
  }

  .modal__form__body {
    .form__body {
      width: 100%;
      height: auto;
      margin: auto;
      border-radius: 10px;
      background-color: ${colors.backgroundColor};
      position: relative;
      top: 20%;

      .form__title {
        width: 100%;
        padding-top: unset;
        text-align: left;
        align-items: center;
        gap: 10px;

        .icon__Form_title {
          color: ${colors.white};
        }
      }
    }

    .container__cards {
      padding-top: unset;
    }
  }

  .left {
    background-color: ${colors.backgroundColor};
    width: 100%;
    height: 100%;
    padding-left: 5px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    ${'' /* min-width: 800px; */}
  }

  .tooltip-column {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;

    tr th:last-child div:last-child {
      justify-content: left;
    }
  }

  thead {
    width: 100%;
    background-color: ${colors.primaryColor};
    border-radius: 10px;
    color: white;
    position: relative;
    margin-left: 10px;
    z-index: 2;
  }

  .nav__buttons__container {
    height: 50px;
    width: 100%;
  }
  .user__table {
    width: 100%;
  }

  .section__name {
    width: max-content;
    display: flex;
    align-items: center;
    color: #0c3fc4;
    height: 30px;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    z-index: 90;
    padding-left: 5px;
  }

  .navtable__container {
    width: 100%;
    height: 35px;
    position: relative;
    top: 0px;
    left: 0px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    text-align: end;
    flex-direction: row-reverse;

    table {
      position: relative;
      top: -40px;
      left: 40px;
    }
  }

  tr th:nth-child(2) {
    border-left: none;
    font-weight: 700;
    cursor: pointer;
    width: 40px;
    min-width: unset;
    width: auto;
  }

  .row {
    color: black;
    width: 100%;
    border-bottom: white 1px solid;
  }

  .pageScreen {
    width: 100%;
    background-color: ${colors.backgroundColor};
    height: auto;
    border-radius: 10px;
    ${'' /* padding-bottom: 100px; */}

    .form__body__users {
      .stepForm__contain-typeInput {
        grid-template-columns: 0.2fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 1fr;
        gap: 0px 0px;
        background-color: unset;
        box-shadow: unset;
      }
    }
    .form__body__users,
    .form__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      border-radius: 0 0 15px 15px;
      width: 100%;
      ${'' /* margin-top: 60px; */}

      .container__cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 15px;
        ${'' /* padding-top: 10px; */}

        .contain__button-save {
          height: 35px;
          width: 100%;
          opacity: 1;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: ${colors.backgroundColor};
          margin-right: 50px;

          .cancel_button {
            background-color: ${colors.primaryColor};
            color: white;
            height: 35px;
            border-radius: 10px;
            width: 100px;
            font-size: 14px;
            margin-right: 20px;
            -webkit-border-radius: 15px;

            &:hover {
              color: white;
              background-color: ${colors.primaryColor};
            }
          }

          .save_edit_button {
            color: black;
            width: 100px;
            height: 35px;
            background-color: ${colors.greenGYGAS};
            font-size: 14px;
            border-radius: 15px;
            -webkit-border-radius: 15px;

            &:hover {
              background-color: ${colors.greenGYGASLight};
            }
          }

          .save_button {
            height: 35px;
            color: black;
            border-radius: 15px;
            width: 100px;
            background-color: ${colors.greenGYGAS};
            font-size: 14px;
            margin-right: 20px;

            &:hover {
              background-color: ${colors.greenGYGASLight};
            }
          }

          .save_button,
          .next_step {
            color: black;
            background-color: ${colors.greenGYGAS};
            border-radius: 15px;
            width: 100px;
            font-size: 14px;
            margin-right: 20px;

            &:hover {
              background-color: ${colors.greenGYGASLight};
            }
          }
        }

        ${
          '' /* .form__title {
          margin-top: -40px;
        } */
        }
        .contain__inputCard-component {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          margin-top: 10px;

          .section__name {
            padding-left: 0;
          }

          ${
            '' /* .stepForm__contain-typeInput {
            grid-template-columns: 0.2fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 1fr;
            gap: 0px 0px;

            background-color: unset;
            box-shadow: unset;
          } */
          }
        }
        .section_name {
          padding-left: 0;
        }

        .contain__select {
          width: unset;
        }
      }
    }

    &__tabs {
      position: relative;
      top: 103px;
      margin: auto;
      width: 80%;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      border: none;
      z-index: 101;
      height: 40px;
      ${'' /* width: max-content; */}
      ${'' /* margin-left: 10%; */}
      ${'' /* margin-top: 90px; */}

      &__tab {
        min-width: 130px;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${colors.blue};

        &:hover {
          cursor: pointer;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;

          // font-size: ${newFontSize.h3};

          border: none;
        }
      }

      .active {
        min-height: 35px;
        padding: 15px;
        color: ${colors.white};
        background-color: ${colors.primaryColorLight};
        border-radius: 5px 5px 0 0;

        &:hover {
          cursor: pointer;
          background-color: ${colors.primaryColorLight};
          ${'' /* border-radius: 10px 10px 0 0; */}
        }
      }

      .inactive {
        min-height: 35px;
        padding: 15px;
        color: ${colors.white};
        border-radius: 5px 5px 0 0;
        background-color: ${colors.primaryColorLight};
        opacity: 0.8;
        border-right: 0.5px solid ${colors.backgroundColor};

        &:hover {
          background-color: ${colors.primaryColor};
          color: ${colors.white};
          opacity: 0.9;
          ${'' /* border-radius: 10px 10px 0 0; */}
          cursor: pointer;
        }
      }
    }

    &__form {
      height: auto;
      width: 100%;

      &__body__ivr {
        margin: auto;
        padding-left: 120px;

        .container__cards {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-top: 20px;
          width: 100%;

          ${
            '' /* .contain__inputCard-component {
            width: 80%;
          } */
          }
        }

        .title__Form_title {
          font-size: 18px;
          ${'' /* padding-left: 120px; */}
        }
      }
    }
  }
`;
