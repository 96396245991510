import styled from 'styled-components';
import { colors } from '../../../theme/colors.js';

export const ListNumberModalWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  background-color: ${colors.backgroundColor};
  border-radius: 15px;

  .form__body__modal {
    width: 100%;
    .container__cards {
      border-radius: 0px 0px 10px 10px;
      .form__title__modal {
        ${'' /* padding-left: 60px; */}
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        gap: 10px;
        font-weight: bold;
        font-size: 24px;
        stroke: ${colors.white};
        color: ${colors.primaryColor};
        ${'' /* background-color: ${colors.primaryColorLight}; */}
        height: 50px;
        margin-top: -50px;
        width: 100%;
        padding: 10px;

        .icon__Form_title {
          width: 35px;
          height: 35px;
          padding: 5px;
          color: rgb(51, 65, 85);
          background-color: rgb(148, 206, 13);
          border-radius: 50%;
          border: 2px solid rgb(51, 65, 85);
        }

        .form__user__logo {
          padding: 10px;
        }

        .title__Form_title {
          display: flex;
          align-items: flex-end;
          color: ${colors.white};
          font-size: 16px;
          border-radius: 15px;
          justify-content: center;
          height: 100%;
        }
      }

      .contain__button-save {
        height: 60px;
        border-radius: 15px 15px 0 0;
        width: 100%;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: unset;
        ${'' /* background-color: ${colors.primaryColorLight}; */};
      }
      .cancel_button {
        background-color: ${colors.primaryColor};
        color: white;
        height: 35px;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;
        -webkit-border-radius: 15px;

        &:hover {
          color: white;
          background-color: ${colors.primaryColor};
        }
      }

      .save_edit_button {
        color: black;
        width: 100px;
        height: 35px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        border-radius: 15px;
        -webkit-border-radius: 15px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button {
        height: 35px;
        color: black;
        border-radius: 15px;
        width: 100px;
        background-color: ${colors.greenGYGAS};
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }

      .save_button,
      .next_step {
        color: black;
        background-color: ${colors.greenGYGAS};
        border-radius: 15px;
        width: 100px;
        font-size: 14px;
        margin-right: 20px;

        &:hover {
          background-color: ${colors.greenGYGASLight};
        }
      }
    }
  }
  ${
    '' /* .contain__button-save {
    position: relative;
    top: -15px;
    right: 10px;
  } */
  }
  ${
    '' /* .container__cards {
    height: 100%;
    background-color: ${colors.primaryColorLight};
    border-radius: 10px;
  } */
  }

  .listnumber__container {
    .container__cards {
      .stepForm__contain-typeInput {
        .contain__select {
          display: flex;
          flex-direction: column;
          gap: 5px;

          div {
            border-radius: 10px;
          }
        }
      }
      .numb__contain-typeInput {
        padding: 60px 40px 40px 40px;
        height: 100%;
        width: 100%;
        margin: auto;
        max-width: 1000px;
        border-radius: 10px;

        .stepForm__contain-typeInput {
          border-radius: 15px;
          ${'' /* background-color: ${colors.primaryColorLight}; */}
          padding: 60px 40px 40px 40px;
          ${'' /* box-shadow: 2px 2px 4px 0px #526581; */}

          ${
            '' /* &:hover {
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
          } */
          }
          span {
            color: ${colors.white};
          }
          .contain__select {
            display: flex;
            flex-direction: column;
            gap: 5px;
            border-radius: 10px;
            div {
              ${'' /* border-radius: 10px; */}
            }
          }
        }
      }
    }
  }

  .form__title {
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding-left: 10px;
    gap: 10px;
    background-color: ${colors.primaryColor};
    color: ${colors.backgroundColor};
  }

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s easy-out;
  }

  .contain__notFound {
    height: calc(100% - 55px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
