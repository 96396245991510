import React, { useEffect, useState } from 'react';

import { MyDropzone } from '../MyDropzone/MyDropzone';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch';
import { Radio } from '../Radio/Radio';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import ColorPicker from '../ColorPicker/ColorPicker';
import { MySelect } from '../MySelect/MySelect';
import { Input } from '../Input/Input';
import Calendar from '../Calendar/Calendar';
import { monthNames, weekDays } from '../../data/dataPreferences';
import { StepFormWrapper } from './StepForm.style';
import { colors } from '../../theme/colors';
import { Phone } from '../Phone/Phone';
import { RandomCodeGenerator } from '../RandomCodeGenerator/RandomCodeGenerator';
import { InputRandom } from '../InputRandom/InputRandom';
import { MultiFormPages } from '../MultiFormPages/MultiFormPages';
import { DestinationSelect } from '../DestinationSelect/DestinationSelect';
import { CardSlider } from '../CardSlider/CardSlider';
import { TitleInputPair } from '../TitleInputPair/TitleInputPair';
import { ToggleButton } from '../ToggleButton/ToggleButton';
import { array, bool, func, number, object, string } from 'prop-types';
// import { ModalPrompt } from '../ModalPrompt/ModalPrompt';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import useApplications from '../../hooks/useApplications';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// import { Button } from '../Button/Button';
import { useLocation } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
// import { IconPencil } from '@tabler/icons-react';
import { ReactSortable } from 'react-sortablejs';
import { IconArrowForwardUpDouble, IconArrowsSort, IconShield, IconMessage, IconWorldUpload } from '@tabler/icons-react';
import { IconArrowsMoveVertical } from '@tabler/icons-react';
import { tooltipsQueue } from '../../data/dataApplications/tooltipsQueue,js';
import useNumbering from '../../hooks/useNumbering';
// import { getArrayFormatted } from '../../utils/AppUtils';
import { IconDotsVertical } from '@tabler/icons-react';
// import useNumbering from '../../hooks/useNumbering';
// import { useAppSelector } from '../../redux/hooks/hooks';
// import { getArrayFormatted } from '../../utils/AppUtils';
// import { now } from 'moment';
// import { getArrayFormatted } from '../../utils/AppUtils';
// import { IconMenuOrder, IconSquareX } from '@tabler/icons-react';

// Sortable.mount(new MultiDrag());

export const StepForm = React.forwardRef(
  (
    {
      dataForm,
      dataTabs,
      initial,
      animate,
      exit,
      isOpen,
      form,
      crudType,
      stepForm,
      layoutId,
      variants,
      errorInput,
      setErrorInput,
      validationNormalInput,
      setValidationNormalInput,
      inputValue,
      setInputValue,
      allSteps,
      maxwidth,
      arrScreen,
      inputValueUnique,
      setInputValueUnique,
      validateReducer,
      setValidateReducer,
      reducerForm,
      data,
      dataModalButtonToTable,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const [loadingCounter, setLoadingCounter] = useState(0);
    const [toggleIsActive, setToggleIsActive] = useState({
      // record: false,
      // isSIPExtension: data.steps[1]?.input[9]?.defaultValues,
      // isVoicemail: data.steps[1]?.input[6]?.defaultValues,
      // isvoicemailEmail: data.steps[1]?.input[8]?.defaultValues,
      isRainbow: data.steps[0]?.input[3]?.label === 'Rainbow' ? data.steps[0]?.input[3]?.defaultValues : 0,
      isTeams: data.steps[0].input[0].label === 'Teams' ? data.steps[0]?.input[0]?.defaultValues : 0,
    });

    const { formExtensionsReducer } = useNumbering();

    const [isDisabled, setIsDisabled] = useState(false);
    const [editRadio, setEditRadio] = useState('');
    const [voiceMail, setVoiceMail] = useState({});
    const [callWaiting, setCallWaiting] = useState({});
    const [selectedDays, setSelectedDays] = useState([
      {
        name: 'Calendario creado',
        color: '',
        days: [],
      },
    ]);
    // const { selectedDoc } = useAppSelector((state) => state.system);

    const location = useLocation();
    const [checkboxesActives, setCheckboxesActives] = useState([]);
    // const [isQueue, setIsQueue] = useState(true);

    // arrScreen[0] === 'Cola' ? setIsQueue(true) : null;
    // const [state, setState] = useState([]);

    useEffect(() => {
      setTimeout(() => {
        try {
          if (crudType === 'edit' || crudType === 'modalEditUserDispo') {
            const hasTabs = Array.isArray(dataTabs?.tabs) ? true : false;

            if (hasTabs) {
              dataTabs?.forEach((tab) => {
                tab.steps[0].input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element !== 'password') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }

                        break;
                      case 'titleInputPair':
                        item.inputs.forEach((input) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: input.defaultValues,
                          }));
                        });
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues.label,
                        }));

                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues?.label,
                        }));

                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        if (!item.defaultValues?.label === undefined) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues || '',
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValue && item.defaultValues[0]?.label !== null ? item.defaultValues[0] : '',
                          }));
                        }
                        // setState2(getFormatted(state2));
                        break;
                      case 'toggle':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'toggleButton':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues,
                        }));
                        break;
                      case 'radio':
                        if (item.ref.element === 'record') setEditRadio(item.defaultValues);
                        else if (item.ref.element === 'voicemailActive') setVoiceMail(item.defaultValues);
                        else if (item.ref.element === 'callWaiting') setCallWaiting(item.defaultValues);
                        else if (item.ref.element === 'preferences') setEditRadio(item.defaultValues);

                        break;
                      default:
                        if (item.ref) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }
                        break;
                    }
                  }
                });
              });
            } else {
              data?.steps.forEach((ItemStep) => {
                ItemStep.input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element !== 'password') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }

                        break;
                      case 'titleInputPair':
                        item.inputs.forEach((input) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: input.defaultValues,
                          }));
                        });
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues || location.state?.typeExtension,
                        }));

                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues,
                          // ?.label,
                        }));

                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        if (!item.defaultValues?.label === undefined) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues || '',
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValue && item.defaultValues[0]?.label !== null ? item.defaultValues[0] : '',
                          }));
                        }
                        // setState2(getFormatted(state2));
                        break;
                      case 'toggle':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'toggleButton':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues,
                        }));
                        break;
                      case 'radio':
                        if (item.ref.element === 'record') setEditRadio(item.defaultValues);
                        else if (item.ref.element === 'voicemailActive') setVoiceMail(item.defaultValues);
                        else if (item.ref.element === 'callWaiting') setCallWaiting(item.defaultValues);
                        else if (item.ref.element === 'preferences') setEditRadio(item.defaultValues);

                        break;
                      default:
                        if (item.ref) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }
                        break;
                    }
                  }
                });
              });
            }
            setLoading(false);
          } else if (crudType === 'create' || crudType === 'createUserDispo') {
            const hasTabs = dataTabs.tabs ? true : false;

            if (hasTabs) {
              dataTabs.tabs.forEach((tab) => {
                tab.input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element === 'pin') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                        }
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = {};
                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = {};

                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = [];
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      case 'phone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      case 'toggle':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      default:
                        break;
                    }
                  }
                });
              });
            } else {
              dataTabs.forEach((tab) => {
                tab.steps.forEach((step) => {
                  step.input.forEach((item) => {
                    if (!stepForm.inputFull) {
                      switch (item.cardType) {
                        case 'input':
                          if (item.ref.element === 'pin') {
                            setInputValue((prevState) => ({
                              ...prevState,
                              [item.ref.element]: item.defaultValues,
                            }));
                          } else {
                            setInputValue((prevState) => ({
                              ...prevState,
                              [item.ref.element]: '',
                            }));
                          }
                          break;
                        case 'select':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = {};
                          break;
                        case 'select-option':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = {};
                          break;
                        case 'multiselect':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = [];
                          break;
                        case 'dropzone':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        case 'phone':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        case 'toggle':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        default:
                          break;
                      }
                    }
                  });
                });
              });
            }
            setLoading(false);
          } else {
            setLoadingCounter(loadingCounter + 1);
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }, 100);
    }, [loadingCounter]);

    const handleToggleSwitchChange = (item, isOn, setIsOn) => {
      setIsOn(isOn === 1 ? 0 : 1);
      setToggleIsActive({
        ...toggleIsActive,
        [item.ref.element]: !isOn,
      });
      const action = { action: 'toggle', value: !isOn === false ? 0 : 1 };

      form(action, item.ref);
    };

    const handleTimeOutChange = (newTimeOut) => {
      const actionTimeOut = { action: 'addTimeOut' };
      const itemTimeOut = { value: newTimeOut.value, label: newTimeOut.label, name: newTimeOut.label, ref: { form: 'ivr' } };
      // newTimeOut.target.value
      const inputValueTimeOut = {
        itemTimeOut,
        value: 'Ivr',
      };
      form(actionTimeOut, { form: 'ivr' }, inputValueTimeOut);

      setInputValue((prevState) => ({
        ...prevState,
        ['timeout']: Number(newTimeOut.value),
      }));

      // setInputValue((prevState) => ({
      //   ...prevState,
      //   timeOut: newTimeOut.value,
      // }));

      // console.log('handleTimeOutChange: ' + newTimeOut.value);
    };

    const handlePhoneChange = (item, value, refForm) => {
      if (!item.title && item.ref !== 'timeout') {
        let action = { action: 'addDestination' };
        let ref = {
          form: refForm,
        };
        let inputValue = {
          item,
          value,
        };
        form(action, ref, inputValue);
      } else if (item.ref === 'timeout') {
        handleTimeOutChange(item);
        console.log('handlePhoneChange timeout: ');
      } else {
        let action = { action: 'removeDestination' };
        let ref = {
          form: refForm,
        };
        let inputValue = {
          item: {
            label: item.label,
            value: item.title,
            ref: item.name,
            name: item.name,
          },
          value: item.title,
        };
        form(action, ref, inputValue);
      }
    };

    const handleRandomCodeGenerator = (item, refForm) => {
      const ref = { form: refForm, element: refForm === 'user' ? 'password' : refForm };

      form({ value: item, action: 'input' }, ref);
    };

    const handleDestinationSelect = (newData, name, item, idPage) => {
      // handleChange();

      if (name) {
        const arg = {
          action: data.ref,
        };
        const ref = {
          element: idPage.ref.element,
          form: idPage.ref.form,
        };
        if (idPage.ref.element === 'destinationTypeId') {
          form(
            { action: 'select-option' },
            {
              element: 'destinationTypeId',
              form: idPage.ref.form,
            },
            { value: Number(newData.value), label: newData.name }
          );
          form({ action: 'select-option' }, { element: 'DestinationType', form: idPage.ref.form }, { value: Number(newData.value), label: newData.name });
          form({ action: 'select-option' }, { element: 'destinationId', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
          form({ action: 'select-option' }, { element: 'Destination', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
        } else {
          form(arg, ref);
        }
      } else {
        const arg = {
          action: idPage.cardType,
        };
        const ref = {
          element: idPage.ref.element,
          form: idPage.ref.form,
        };

        form(arg, ref, data);
      }

      // handleOnChange();
    };

    const [isActiveToggleButton, setIsActiveToggleButton] = useState(true);

    const handleToggleButtonChange = (item) => {
      const newValue = !isActiveToggleButton;

      setIsActiveToggleButton(newValue);
      const action = {
        action: item.cardType,
      };
      const ref = {
        form: item.ref.form,
        element: item.ref.element,
      };
      // const newValue = item.defaultValues === 2 ? 1 : 2;

      setInputValue((prevState) => ({
        ...prevState,
        [item.ref.element]: newValue,
      }));

      form(action, ref, newValue);
    };

    // const [, actions] = useFormState();
    // const [isLostMode, setIsLostMode] = useState(false);
    // const [isFormDirty, setIsFormDirty] = useState(false);

    // function handleLostModeChange(e) {
    //   const checked = e.target.checked;
    //   setIsLostMode(checked);
    //   if (checked) {
    //     sessionStorage.removeItem('isFormDirty');
    //   }
    // }

    // useEffect(() => {
    //   addEventListener('change', (eventInput) => {
    //     const field = eventInput.target.id;
    //     const value = eventInput.target.value;
    //     actions.setField(field, value);

    //     handleLostModeChange(eventInput);

    //     if (!isLostMode) {
    //       sessionStorage.isFormDirty = 'true';
    //       setIsFormDirty(true);
    //       console.log('changed useState: ' + value);
    //     }
    //   });
    // }, []);

    // function useFormState() {
    //   const initialState = JSON.parse(sessionStorage.formState || '{}');

    //   const [name, setName] = useState(initialState.name || '');
    //   const [address, setAddress] = useState(initialState.address || '');
    //   const [phone, setPhone] = useState(initialState.phone || '');
    //   const [timeout, setTimeOut] = useState(initialState.timeOut || 2);

    //   const state = {
    //     name,
    //     address,
    //     phone,
    //     timeout,
    //   };

    //   const actions = {
    //     clearForm: () => {
    //       setName('');
    //       setAddress('');
    //       setPhone('');
    //       setTimeOut(2);
    //     },

    //     saveForm: () => {
    //       sessionStorage.formState = JSON.stringify({ name, address, phone, timeout });
    //     },
    //     // /**
    //     //  * @param {'name'|'address'|'phone'|'timeout'} field
    //     //  * @param {string | number} value
    //     //  */
    //     setField: (field, value) => {
    //       if (field === 'name') {
    //         setName(value);
    //       }
    //       if (field === 'address') {
    //         setAddress(value);
    //       }
    //       if (field === 'phone') {
    //         setPhone(value);
    //       }
    //       if (field === 'timeout') {
    //         setTimeOut(Number(value));
    //       }
    //     },
    //   };
    //   return [state, actions];
    // }

    // const setShowWarning = (status) => {
    //   event.preventDefault(event);
    //   if (status !== undefined) {
    //     setIsFormDirty(status);

    //     console.log('setShowWarning updated: ' + status + ' - ' + isFormDirty);
    //   }
    // };

    const [isAudioPlayerOpen, setIsAudioPlayerOpen] = useState(false);

    // const isPlayerOpen = !isOpen;

    // useEffect(() => {
    //   setAudioPlayer();
    // }),
    //   [isAudioPlayerOpen];
    // isLostMode && isFormDirty ? (event.returnValue = '') : '';

    // const handleOnChange = () => {
    //   console.log('data changed: ');
    // };

    const handleSelectedTracks = (value) => {
      const newSelectedTracks = value.length > 0 ? value : checkboxesActives;
      const newLength = newSelectedTracks.length;

      if (newLength > 0) {
        console.log('selectedTracks updated to: ' + newLength.toString() + ' items, new value: ' + newSelectedTracks[newLength - 1].description);
        setCheckboxesActives(newSelectedTracks);
        setInputValue({ checkboxesActives, value });
        return newSelectedTracks;
      }
    };
    // function onlyUnique(value, index, array) {
    //   return array.indexOf(value) === index;
    // }

    // const allTracksUnique = songWaitingLink.filter(onlyUnique);
    // useEffect(() => {
    //   setCheckboxesActives(checkboxesActives);
    // }, [checkboxesActives]);

    // const isSongWaiting = location.pathname?.includes('songWaiting');

    // const { songWaitingLink, songWaitingForm } = useApplications();

    const [objectSongWaiting, setObjectSongWaiting] = useState({
      arraySongWaiting: [],
      title: '',
    });

    const isSongWaiting = location.pathname?.includes('songWaiting');
    const { songWaitingLink, songWaitingForm, queuesForm } = useApplications();

    const setAudioPlayer = (status) => {
      event.preventDefault(event);
      if (status !== undefined && isSongWaiting) {
        // console.log('IsAudioPlayerOpen status: ' + isAudioPlayerOpen);
        setIsAudioPlayerOpen(!isAudioPlayerOpen);
        const newSongWaiting = songWaitingLink.filter((object) => (object.mohId !== undefined ? object.mohId === songWaitingForm.id : ''));
        setObjectSongWaiting({
          arraySongWaiting: newSongWaiting,
          title: status.original?.filename,
        });
        console.log('IsAudioPlayerOpen status: ' + isAudioPlayerOpen);
      }
    };
    const isUpdate = location.pathname.includes('update');
    // const newState = dataTabs && dataTabs[2]?.steps[0]?.input[1]?.defaultValues !== undefined ? dataTabs[2]?.steps[0]?.input[1]?.defaultValues : '';

    // const handleQueueStates = () => {
    //   const newValue =
    //     inputValue?.extensions && inputValue?.extensions.length > 0
    //       ? inputValue
    //       : !location.state?.extensions || location.state?.extensions[0]?.value === undefined
    //         ? getArrayFormatted(location.state?.extensions, arrayNumberingExtensionsHook, 'sort')
    //         : location.state;

    //   return newValue;
    // };

    // const newState = arrScreen[0] === 'Cola' ? handleQueueStates() : [];

    //  || location.state?.extensions
    // || location.state?.extensions || (dataTabs && dataTabs[2]?.steps[0]?.input[1]?.defaultValues);

    // dataTabs[1]?.steps[3]?.input[1]?.defaultValues;
    const [valueSelect2, setValueSelect2] = useState(location.state?.destinationId);
    const [isExtEdit, setIsExtEdit] = useState(false);
    const [state2, setState2] = useState(queuesForm?.extensions);
    // ? queuesForm.extensions : location.state?.extensions
    // [0].value ? newState : getArrayFormatted(newState, arrayNumberingExtensionsHook, 'sort');
    //   newState && newState[0] !== undefined && Array.isArray(newState[0]) === true ? newState[0] : getArrayFormatted(newState, arrayNumberingExtensionsHook, 'sort')
    // );
    // const [state2, setState2] = useState(inputValue.extensions ? inputValue.extensions : getArrayFormatted(location.state?.extensions, arrayNumberingExtensionsHook, 'sort'));
    // const [showOrderList, setShowOrderList] = useState(false);

    // const getFormatted = (state) => {
    //   const paramIsArray = Array.isArray(state);
    //   // state = !isArray ? [state] : state;

    //   const newArray = [];

    //   paramIsArray
    //     ? state.forEach((newValue) => {
    //         const newExt = arrayNumberingExtensionsHook?.find((ext2) => ext2.id.toString() === (newValue.id !== undefined ? newValue.id : newValue?.value !== undefined ? newValue.value : newValue));
    //         newArray.push({ id: Number(newExt?.id || newValue?.id || newValue), name: newExt?.name || newValue?.name || newExt?.name });
    //       })
    //     : newArray.push({ id: Number(state?.value), name: state?.label });

    //   return newArray;
    // };

    const handleIsExtEdit = () => {
      setIsExtEdit(!isExtEdit);
    };

    const handleEndSorting = (event) => {
      const { oldIndex, newIndex } = event;

      if (oldIndex !== newIndex) {
        const newArray = arrayMoveImmutable(queuesForm?.extensions ? queuesForm.extensions : location.state?.extensions, oldIndex - 1, newIndex - 1);
        // location.state?.extensions = newArray;
        // const result = getArrayFormatted(newArray, [], 'sort');
        setState2(newArray);
        // setInputValue('extensions', result);
        setInputValue((prevState) => ({
          ...prevState,
          extensions: newArray,
        }));

        form(
          {
            action: 'multiselect',
            value: newArray,
          },
          {
            form: 'queue',
            element: 'extensions',
          }
        );
        // setState2(newArray);
      }
    };

    // const handleToolTipContent = (event) => {
    //   switch (event) {
    //     case 'extension':
    //       return tooltipsQueue.tooltips[0].extension;

    //     default:
    //       return tooltipsQueue.tooltips[0].extension;
    //   }
    // };
    // const handleEndSorting = ({ oldIndex, newIndex }) => {
    //   const newArray = arrayMoveImmutable(state2, oldIndex, newIndex);

    //   newArray[0].value !== undefined ? setState2(getFormatted(newArray)) : null;
    // };

    // const newNumber = '1';

    const handleIsDisabled = (item) => {
      let newValue = false;
      switch (item.ref.form) {
        case 'extension':
          switch (item.ref.element) {
            case 'allow':
              newValue = formExtensionsReducer?.typeExtension?.value === 'SIP' || inputValue?.typeExtension?.value === 'SIP' ? false : true;
              break;
            case 'userId':
              newValue = false;
              // crudType === 'modalCreateUserDispo' || crudType === 'modalEditUserDispo' ? true : false;
              break;
            case 'marca':
              newValue = formExtensionsReducer?.typeExtension?.value === 'terminal' || inputValue?.typeExtension?.value === 'terminal' ? false : true;
              break;
            case 'modelo':
              newValue = formExtensionsReducer?.typeExtension?.value === 'terminal' || inputValue?.typeExtension?.value === 'terminal' ? false : true;
              break;
            case 'plantilla':
              newValue = formExtensionsReducer?.typeExtension?.value === 'terminal' || inputValue?.typeExtension?.value === 'terminal' ? false : true;
              break;
            case 'identification':
              newValue = true;
              break;
            case 'typeExtension':
              // setIsDisabled(newValue);
              handleIsDisabled({ ref: { form: 'extension', element: 'marca' } });
              handleIsDisabled({ ref: { form: 'extension', element: 'modelo' } });
              handleIsDisabled({ ref: { form: 'extension', element: 'plantilla' } });
              handleIsDisabled({ ref: { form: 'extension', element: 'allow' } });
              newValue = false;
              break;
            default:
              return false;
          }
          break;
        case 'locution':
          if (item.ref.element === 'type' && item.defaultValues !== 1) {
            newValue = true;
          } else {
            newValue = false;
          }
          break;
        default:
          return false;
      }

      // setIsDisabled(newValue);
      return newValue;
    };

    const handleClassInput = () => {
      let newClass = 'stepForm__contain-typeInput';
      switch (arrScreen[0]) {
        case 'Cola':
          newClass = data.id === 3 ? 'queue__contain-typeInput' : newClass;
          break;
        case 'Ivr':
          newClass = 'ivr__contain-typeInput';
          break;
        case 'Música en espera':
          newClass = 'soundw__contain-typeInput';
          break;
        case 'Numeración externa':
          newClass = 'numb__contain-typeInput';
          break;
        default:
          newClass = 'stepForm__contain-typeInput';
          break;
      }
      return newClass;
    };

    // const handleResult = (result, result2) => {
    //   const newResult = [];

    //   newResult.push(result);
    //   newResult.push(result2);

    //   return newResult;
    // };
    // const valuesArray = state2 || [queuesForm.extensions];

    return (
      <StepFormWrapper initial={initial} variants={variants} animate={animate} exit={exit} layoutId={layoutId} ref={ref} data={dataForm} maxwidth={maxwidth}>
        {/* {isLostMode && isFormDirty ? <ModalPrompt content={['Aviso']} selectedValue={(value) => value} isOpen={setShowWarning} /> : ''} */}

        {loading && crudType === 'edit' ? (
          <LoaderSpinner />
        ) : isUpdate && arrScreen[0] !== 'Preferencias' && arrScreen[0] !== 'Cola' && arrScreen[0] !== 'Usuario' ? (
          //  && arrScreen[0] !== 'Cola'
          <div className="stepForm">
            <div className="stepForm__header">
              {/* <div className="stepForm__number">
                <p className="number">{dataForm.number}</p>
              </div> */}
              <div className="stepForm__title">
                <p className="title">{dataForm.title}</p>
              </div>
            </div>
          </div>
        ) : null}

        {isOpen ? (
          <div className={handleClassInput()}>
            {dataForm.input.map((item, index) => {
              const result = item.title && (
                <span className="stepForm__section__title" id={item.title !== '' ? item.title : 'Nuevo'}>
                  {item.title === 'Seguridad' ? (
                    <IconShield key={'item.title' + '__' + index} />
                  ) : item.title === 'Avisos' ? (
                    <IconMessage key={'item.title' + '__' + index} />
                  ) : item.title === 'Desvios' ? (
                    <IconArrowForwardUpDouble key={'item.title' + '__' + index} />
                  ) : item.title === 'ServiciosExternos' ? (
                    <IconWorldUpload />
                  ) : (
                    item.title
                  )}
                </span>
              );
              const result2 =
                item.cardType === 'select' || item.cardType === 'select-option' ? (
                  <div key={item.ref.element + '__' + index} className="contain__select" id={item.ref.element + '__' + index}>
                    {item.required && <span className="textRequired">{item.required.text}</span>}
                    <MySelect
                      item={item}
                      placeholder={item.placeholder}
                      options={item.options}
                      inputRef={ref}
                      defaultValue={[inputValue[item.ref.element]] || [item.defaultValues]}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      handleIsDisabled={() => handleIsDisabled(item)}
                      // defaultValue={item.ref.element !== 'companyId' || item.ref.element !== 'departmentId' ? inputValue[item.ref.element] : item.defaultValues}
                      // item.ref.element !== 'companyId' || item.ref.element !== 'departmentId' ? inputValue[item.ref.element] : item.defaultValues}
                      isMulti={(item.ref.form !== item.ref.element) === 'captureId' ? true : false}
                      isSearchable={false}
                      form={form}
                      dataForm={dataForm}
                      setInputValue={setInputValue}
                      value={Array.isArray(inputValue[item.ref.element]) ? inputValue[item.ref.element] : [inputValue[item.ref.element]]}
                      // value={item.ref.element === 'companyId' ? inputValue.companyId : item.defaultValues}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      // isDisabled={handleIsDisabled}
                      onChange={() => handleIsDisabled(item)}
                      reducerForm={reducerForm}
                      firstData={data}
                      setValidateReducer={setValidateReducer}
                    />
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip
                        className="tooltip"
                        anchorSelect={'#' + item.ref.element + '__' + index}
                        place="bottom"
                        content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                        delayShow={500}
                      />
                    ) : null}
                    {arrScreen[0] === 'Buzón' && item.ref.element === 'soundPrompt' && item?.defaultValues?.label ? (
                      <audio src={'/uploads/mp3/' + item?.defaultValues?.label + '.mp3'} controls />
                    ) : null}
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                  </div>
                ) : item.cardType === 'multiselect' ? (
                  <div key={item.ref.element + '__' + index} className="contain__select" id={item.ref.element + '__' + index}>
                    {item.required && <span className="textRequired">{item.required.text}</span>}

                    <MySelect
                      item={item}
                      placeholder={item.placeholder}
                      options={item.options}
                      inputRef={ref}
                      setInputValue={setInputValue}
                      defaultValue={Array.isArray(item.defaultValues) ? item.defaultValues : [item.defaultValues]}
                      isMulti={true}
                      isSearchable={true}
                      value={Array.isArray(item.defaultValues) ? item.defaultValues : [item.defaultValues]}
                      // value={[inputValue]}
                      form={form}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      handleIsDisabled={() => handleIsDisabled(item)}
                      // isDisabled={item.ref.form !== 'user' ? () => handleIsDisabled(item) : null}
                      dataForm={dataForm}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      onChange={() => handleIsDisabled(item)}
                      reducerForm={reducerForm}
                      firstData={data}
                      setValidateReducer={setValidateReducer}
                      setState2={setState2}
                      // value={item.defaultValues}
                    />

                    {state2 && item.ref.element === 'extensions' && item.ref.form === 'queue' ? (
                      <div className="sortableDrag__container" key={item.ref.element + '__' + index} onClick={handleIsExtEdit}>
                        <ReactSortable
                          dragClass="sortableDrag"
                          ghostClass="ghost"
                          // isDisabled={handleIsDisabled(item)}
                          swap={true}
                          swapClass="highlighted"
                          animation="200"
                          easing="ease-out"
                          className="sorted__container"
                          list={state2}
                          delayOnTouchStart={true}
                          delay={2}
                          removeCloneOnHide={true}
                          setList={setState2}
                          chosenClass="queue__selected"
                          onEnd={handleEndSorting}>
                          <div className="sorted__header">
                            <span className="sorted__title">Arrastrar para ordenar</span>
                            <IconArrowsSort className="sorted__header__icon" />
                            {/* id={'icon__sort'} */}
                          </div>
                          {/* {getArrayFormatted(item.defaultValues, [], 'onlyFormat') */}
                          {state2.map((itemList, index) =>
                            itemList.value ? (
                              <div key={itemList.label + '__' + index} className="sorted__row">
                                <span className="sorted__pos"> {index + 1}</span>
                                <div data-id={itemList.value + '-' + index} className="sorted__item">
                                  <IconDotsVertical className="item__icon" />
                                  {itemList.label || item.options[index]?.label}
                                  <IconArrowsMoveVertical className="item__icon" />
                                </div>
                              </div>
                            ) : null
                          )}
                        </ReactSortable>
                      </div>
                    ) : null}
                    {isAudioPlayerOpen ? (
                      <AudioPlayer
                        title={inputValue.name}
                        allTracks={arrScreen}
                        // selectedTracks={checkboxesActives.length > 0 ? checkboxesActives : objectSongWaiting.arraySongWaiting}
                        selectedTracks={checkboxesActives.length > 0 ? checkboxesActives : objectSongWaiting.arraySongWaiting}
                        isOpen={setIsAudioPlayerOpen}
                        isEditable={true}
                        setCheckboxesActives={handleSelectedTracks}
                        // setInputValue={setInputValue}
                        // checkboxesActives={checkboxesActives.length > 0 ? checkboxesActives : objectSongWaiting.arraySongWaiting}
                        checkboxesActives={objectSongWaiting.arraySongWaiting.map((itemLocucion) => itemLocucion.Soundprompt)}
                        setAudioPlayer={setAudioPlayer}
                      />
                    ) : null}
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                  </div>
                ) : item.cardType === 'input' ? (
                  crudType === 'edit' && item.ref.element === 'password' ? null : (
                    <div className="contain__input" id={item.ref.element + '__' + index}>
                      {/* id={item.ref.element + '__' + index} */}
                      {item.required ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"></span>}
                      <Input
                        onChange={handleTimeOutChange}
                        item={item}
                        form={form}
                        // inputValue={inputValue || ''}
                        // [item.ref.element] === Object ? '' : inputValue[item.ref.element]
                        inputValue={inputValue}
                        setErrorInput={setErrorInput}
                        setInputValue={setInputValue}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        arrScreen={arrScreen}
                        inputValueUnique={inputValueUnique}
                        setInputValueUnique={setInputValueUnique}
                        isDisabled={isDisabled}
                      />
                      {arrScreen[0] === 'Cola' ? (
                        <ReactTooltip
                          className="tooltip"
                          anchorSelect={'#' + item.ref.element + '__' + index}
                          place="bottom"
                          content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                          delayShow={500}
                        />
                      ) : null}
                      {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                      {inputValueUnique.some((obj) => obj.element === item.ref.element) ? <span className="error"> {item.required.errorMessageUnique}</span> : null}
                    </div>
                  )
                ) : item.cardType === 'inputRandom' ? (
                  crudType === 'edit' && item.ref.element === 'password' ? null : (
                    <div className="contain__input" key={item.ref.element + '__' + item.id}>
                      {/* id={item.ref.element + '__' + index} */}
                      {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired" />}
                      <InputRandom
                        onChange={handleTimeOutChange}
                        item={item}
                        form={form}
                        // arrScreen={arrScreen}
                        inputValue={inputValue}
                        setErrorInput={setErrorInput}
                        setInputValue={setInputValue}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                      />
                      {arrScreen[0] === 'Cola' ? (
                        <ReactTooltip
                          className="tooltip"
                          anchorSelect={'#' + item.ref.element + '__' + index}
                          place="bottom"
                          content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                          delayShow={500}
                        />
                      ) : null}
                      {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                    </div>
                  )
                ) : item.cardType === 'dropzone' ? (
                  <div key={item.ref.element + '__' + item.id} className="contain__form-dropzone">
                    <div className="dropzone">
                      {item.required.validation && <span className="textRequired">{item.required.text}</span>}
                      <MyDropzone
                        // isDisabled={handleIsDisabled(item)}
                        item={item}
                        setInputValue={setInputValue}
                        form={form}
                        inputValue={inputValue}
                        reducerForm={reducerForm}
                        accept={item.acceptFile}
                      />
                    </div>
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : <span className="error">&nbsp;</span>}
                  </div>
                ) : item.cardType === 'toggle' ? (
                  <div key={item.ref.element + '__' + index} id={item.ref.element + '__' + index} className={arrScreen[0] === 'Cola' && data.id === 3 ? 'queue__toggle' : 'stepForm__toggle'}>
                    {item.required.text && <span className="textRequired">{item.required.text}</span>}

                    <div className="toggle__item">
                      <ToggleSwitch toggleSwitch={handleToggleSwitchChange} isActive={toggleIsActive} data={item} color={colors.greenGYGAS} />
                    </div>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip
                        className="tooltip"
                        anchorSelect={'#' + item.ref.element + '__' + index}
                        place="bottom"
                        content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                        delayShow={500}
                      />
                    ) : null}
                  </div>
                ) : item.cardType === 'radio' ? (
                  item.ref.element === 'voicemailActive' ? (
                    <div key={item.ref.element + '__' + item.id} className="stepForm__radio">
                      <span className="textRequired">{item.required.text}</span>

                      {item.required.validation && item.radioType === 'multi' && <span>{item.required.text}</span>}
                      {/* id={item.ref.element}> */}
                      <div className="contain__items-radio">
                        <Radio
                          form={form}
                          item={item}
                          defaultValueEdit={editRadio}
                          voiceMail={voiceMail}
                          dataForm={dataForm}
                          inputRef={ref}
                          setInputValue={setInputValue}
                          setErrorInput={setErrorInput}
                          setValidationNormalInput={setValidationNormalInput}
                          validationNormalInput={validationNormalInput}
                          allSteps={allSteps}
                        />{' '}
                      </div>
                    </div>
                  ) : (
                    <div key={item.ref.element + '__' + index} className="stepForm__radio">
                      {item.required.text && (
                        <span className="textRequired">
                          {/* id={item.ref.element}>*/}
                          {item.required.text}
                        </span>
                      )}

                      {item.required.validation && item.radioType === 'multi' && <span className={item.ref.element}> {item.required.text}</span>}
                      <div className="contain__items-radio">
                        <Radio
                          item={item}
                          form={form}
                          defaultValueEdit={editRadio}
                          voiceMail={callWaiting}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setValidationNormalInput={setValidationNormalInput}
                          validationNormalInput={validationNormalInput}
                          setErrorInput={setErrorInput}
                          arrScreen={arrScreen}
                          inputValueUnique={inputValueUnique}
                          setInputValueUnique={setInputValueUnique}
                        />
                      </div>
                    </div>
                  )
                ) : item.cardType === 'schedule' ? (
                  <div key={item.ref.element + '__' + index} className="schedule_container">
                    {item.required.text && <span className="textRequired">{item.required.text}</span>}

                    <ScheduleForm item={item} form={form} crudType={crudType} />
                  </div>
                ) : item.cardType === 'calendar' ? (
                  <div key={item.ref.element + '__' + index} className="calendar_container">
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <div className="calendar_wrapper">
                      <Calendar selectedDays={selectedDays} setSelectedDays={setSelectedDays} monthNames={monthNames} weekDays={weekDays} monthsDisplayed={2} data={item} crudType={crudType} />
                    </div>
                  </div>
                ) : item.cardType === 'color' ? (
                  <div key={item.ref.element + '__' + index} className="color">
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <ColorPicker crudType={crudType} data={item} />
                  </div>
                ) : item.cardType === 'phone' ? (
                  <div key={item.ref.element + '__' + index} className={'containerPhone'}>
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <Phone setInputValue={setInputValue} form={form} onChange={handlePhoneChange} refForm={item.ref.form} crudType={crudType} item={item} />
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : ''}
                  </div>
                ) : item.cardType === 'generateKey' ? (
                  <div key={item.ref.element + '__' + item.id} className={item.ref.form === 'user' ? 'generatedPassword' : 'generateKey'}>
                    <RandomCodeGenerator refForm={item.ref.form} onChange={handleRandomCodeGenerator} />
                  </div>
                ) : item.cardType === 'multiFormPages' ? (
                  <div key={item.ref.element + '__' + item.id} className={'container_multiFormPagesWrapper'}>
                    <MultiFormPages
                      data={item}
                      form={form}
                      dataForm={dataForm}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      validateReducer={validateReducer}
                      //
                      setValidateReducer={setValidateReducer}
                      reducerForm={reducerForm}
                      firstData={data}
                    />
                  </div>
                ) : item.cardType === 'destinations' ? (
                  <div key={item.ref.element + '__' + index} className="contain__select" id={item.ref.element + '__' + index}>
                    {item.required && (
                      <span className="textRequired">
                        {/* id={item.ref.element+'__'+index}> */}
                        {item.required.text}
                      </span>
                    )}

                    <DestinationSelect
                      onChange={handleDestinationSelect}
                      item={item}
                      placeholder={item.placeholder}
                      options={item.options}
                      inputRef={ref}
                      defaultValue={item.defaultValues === '' ? {} : item.defaultValues}
                      isMulti
                      isSearchable={true}
                      form={form}
                      dataForm={dataForm}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      setValueSelect2={setValueSelect2}
                      valueSelect2={valueSelect2}
                    />

                    <span className="basic__destination">{valueSelect2}</span>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip
                        className="tooltip"
                        anchorSelect={'#' + item.ref.element + '__' + index}
                        place="bottom"
                        content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                        delayShow={500}
                      />
                    ) : null}
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                  </div>
                ) : item.cardType === 'cardSlider' ? (
                  <div key={item.ref.element + '__' + item.id}>
                    <span className="textRequired">
                      {/* id={item.ref.element}> */}
                      {item.label}
                    </span>
                    <CardSlider
                      item={item}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      form={form}
                      dataModalButtonToTable={dataModalButtonToTable}
                      dataForm={dataForm}
                    />
                  </div>
                ) : item.cardType === 'titleInputPair' ? (
                  <div key={item.ref.element + '__' + item.id} className="contain__titleInputPair">
                    <TitleInputPair
                      item={item}
                      form={form}
                      inputValue={inputValue}
                      setErrorInput={setErrorInput}
                      setInputValue={setInputValue}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      arrScreen={arrScreen}
                      inputValueUnique={inputValueUnique}
                      setInputValueUnique={setInputValueUnique}
                      errorInput={errorInput}
                    />
                  </div>
                ) : item.cardType === 'toggleButton' ? (
                  <div className="contain__toggleButton" key={item.ref.element + '__' + index}>
                    <span className="textRequired">{item.label}</span>
                    <div className="contain__toggleButton__item" onClick={() => handleToggleButtonChange(item)}>
                      <ToggleButton
                        textLeft={item.textLeft}
                        textRight={item.textRight}
                        isActive={isActiveToggleButton}
                        setIsActive={setIsActiveToggleButton}
                        twoButtonActive="true"
                        defaultValue={item.defaultValues === 1 || item.defaultValues === 'true' || item.defaultValues === true ? true : false}
                        setInputValue={setInputValue}
                        form={form}
                        // isDisabled={handleIsDisabled(item)}
                      />
                    </div>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip
                        className="tooltip"
                        anchorSelect={'#' + item.ref.element + '__' + index}
                        place="bottom"
                        content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]}
                        delayShow={500}
                      />
                    ) : null}{' '}
                  </div>
                ) : null;
              const newResult = [];
              newResult.push(result);
              newResult.push(result2);
              return newResult;
            })}
          </div>
        ) : null}
      </StepFormWrapper>
    );
  }
);

StepForm.displayName = 'StepForm';

StepForm.propTypes = {
  dataForm: object,
  initial: string,
  animate: string,
  exit: string,
  isOpen: bool,
  form: func,
  crudType: string,
  stepForm: object,
  layoutId: number,
  variants: object,
  errorInput: bool,
  setErrorInput: func,
  validationNormalInput: array,
  setValidationNormalInput: func,
  inputValue: object,
  setInputValue: func,
  allSteps: array,
  maxwidth: string,
  arrScreen: array,
  inputValueUnique: array,
  setInputValueUnique: func,
  //
  validateReducer: array,
  setValidateReducer: func,
  reducerForm: object,
  data: object,
  dataTabs: array,
  children: object,
  dataModalButtonToTable: object,
};
