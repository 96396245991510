import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const WideWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 15px;
  ${'' /* overflow: auto; */}

  .title__Form_title {
    font-size: 16px;
    ${'' /* padding-left: 90px; */}
    ${'' /* padding-top: 25px; */}
  }

  .left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
  }
`;
