import React, { useState, useEffect, useRef } from 'react';
import AudioControls from './AudioControls';
// import Backdrop from './Backdrop';
import './AudioPlayer.style.css';
import { array, bool, func, string } from 'prop-types';
import { getLocutionsAction } from '../../redux/actions/applications/actionCreators';
import { IconMusic, IconX } from '@tabler/icons-react';
import { Checkbox } from '../Checkbox/Checkbox';
// import { NavTable } from '../NavTable/NavTable';
// import { useMemo } from 'react';

const AudioPlayer = ({ isOpen, title, allTracks, checkboxesActives, setCheckboxesActives, isEditable }) => {
  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  // const [checkBoxAllSelected, setCheckBoxAllSelected] = useState(false);
  // const [checkboxesActives, setCheckboxesActives] = useState(selectedTracks);
  // const [, setAudios] = useState([]);
  const tracksList = isEditable || checkboxesActives === undefined ? allTracks : checkboxesActives;
  // .map((a) => a.Company)
  const initTrackState = { description: '', filename: '', name: '' };
  // Destructure for conciseness
  // const { description, filename } = tracks[trackIndex] !== undefined ? tracks[trackIndex].Soundprompt : initTrackState;
  const { description, filename } = tracksList && tracksList[trackIndex] !== undefined ? tracksList[trackIndex] : initTrackState;
  // tracksList[trackIndex].Soundprompt !== undefined ? tracksList[trackIndex].Soundprompt : tracksList[trackIndex] !== undefined ? tracksList[trackIndex] : initTrackState;
  // const { name } = tracks[trackIndex] !== undefined ? tracks[trackIndex].Moh : initTrackState;
  // const { name } = selectedTracks[trackIndex] !== undefined && selectedTracks[trackIndex].Moh !== undefined ? selectedTracks[trackIndex].Moh : initTrackState;
  // const color = '#00aeb0';
  // Refs

  const searchLocutions = (track) => {
    console.log('Search id for name: ' + track);
    const newArrayLocutions = getLocutionsAction(track);

    return newArrayLocutions.locutions;
  };

  if (filename === '') {
    tracksList && searchLocutions(tracksList[0][0]);
  }

  const audioSrc = import.meta.env.VITE_BACK_URL.replace('/api', '/uploads') + 'mp3/' + filename;
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const [duration, setDuration] = useState(audioRef.current);

  const currentPercentage = !isNaN(duration) ? `${(trackProgress / duration) * 100}%` : '100%';
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;
  const startTimer = () => {
    // Clear any timers already running
    // setIsPlaying(false);

    // audioRef.current.pause();
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        isReady.current = false;
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
    setIsPlaying(false);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracksList.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }

    clearInterval(intervalRef.current);
    setTrackProgress(audioRef.current.currentTime);
  };

  const toNextTrack = () => {
    if (trackIndex < tracksList.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
      // audioRef.current.pause();
      // setIsPlaying(false);
    }
    clearInterval(intervalRef.current);
    setTrackProgress(audioRef.current.currentTime);
  };

  useEffect(() => {
    if (isPlaying) {
      setDuration(audioRef.current.duration);
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      audioRef.current.pause();
      setDuration(0);
      // setIsPlaying(false);
    }
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    // audioRef.current.pause();
    audioRef.current = new Audio(filename);
    setTrackProgress(audioRef.current.currentTime);
    // setIsPlaying(true);

    if (isReady.current) {
      audioRef.current.src = audioSrc;
      audioRef.current.play();

      setIsPlaying(true);
      startTimer();
      // } else {
      //   isReady.current = true;
      //   audioRef.current.pause();
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleSelect = (event) => {
    audioRef.current.pause();
    setIsPlaying(false);
    const newTrackIndex = Number(event.currentTarget.id);
    setTrackIndex(newTrackIndex);
    audioRef.current.src = audioSrc;
    setIsPlaying(true);
    // audioRef.current.play();
    isReady.current = true;
  };

  const handleMouseLeave = () => {
    isOpen(() => false);
    setIsPlaying(false);
    setTrackIndex(0);
  };

  // useEffect(() => {
  //   // console.log('checkboxesActives: ' + checkboxesActives);
  //   setCheckboxesActives(checkboxesActives);
  // }, [checkboxesActives]);

  return (
    <div className="audio-player" onMouseLeave={handleMouseLeave}>
      <div className="track-info">
        {/* <img className="artwork" src={image} alt={`track artwork for ${title} by ${artist}`} /> */}
        <div className="player-header">
          <span className="player-title">Locuciones</span>
          <IconX className="player-btn-close" height={'22px'} width={'22px'} onClick={handleMouseLeave} />
        </div>
        <span className="track-title">{title}</span>
        <IconMusic style={{ padding: '5px', margin: 'auto', width: '50px', height: '50px' }} />
        <span className="description">{description}</span>
      </div>
      <AudioControls isPlaying={isPlaying} onPrevClick={toPrevTrack} onNextClick={toNextTrack} onPlayPauseClick={setIsPlaying} />
      <input
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max={duration ? duration : `${duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
      />
      {/* </div> */}
      {/* <Backdrop trackIndex={trackIndex} activeColor={color} isPlaying={isPlaying} /> */}

      <div className="playlist-container">
        {tracksList?.map((track, index) => {
          return (
            <div className="item-container" key={index}>
              {/* <NavTable checkboxesActives={checkboxesActives} /> */}
              {/* const found = checkboxesActives.some((checkbox) => checkbox.id === row.id || (checkbox.soundpromptId && checkbox.soundpromptId === row.id)); */}
              <div className={tracksList.some((checkbox) => checkbox.value === track?.id?.toString() || checkbox?.id === track?.id) ? 'playlist-item-selected' : 'playlist-item'} id={index}>
                {isEditable && (
                  <Checkbox
                    // checkBoxAllSelected={checkBoxAllSelected}
                    // setCheckBoxAllSelected={setCheckBoxAllSelected}

                    row={track}
                    id={track.id}
                    checkboxesActives={checkboxesActives}
                    setCheckboxesActives={setCheckboxesActives}
                    className={'playlist-item-checkbox'}
                    // setInputValue={setInputValue}
                    // checked={handleIsChecked}
                    // onClick={handleIsChecked}
                  />
                )}
                <IconMusic width={'50px'} onClick={handleSelect} />
                <span className={'playlist-item-title'} onClick={handleSelect} id={index}>
                  {track?.description || track.Soundprompt?.description}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <span className="playlist-counter">{/* {checkboxesActives.length.toString()} / {tracksList.length.toString()} seleccionados */}</span>
    </div>
  );
};

export default AudioPlayer;

AudioPlayer.propTypes = {
  COLUMNS: array,
  handleMouseOut: func,
  isOpen: func,
  allTracks: array,
  selectedTracks: array,
  title: string,
  artist: string,
  color: string,
  image: string,
  audioSrc: string,
  isEditable: bool,
  checkboxesActives: array,
  setCheckboxesActives: func,
  setInputValue: func,
};
